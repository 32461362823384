import theme from '@styles/theme';

export const CalendarBirthdayContained = ({
  width = 14,
  height = 15,
  stroke = theme.palette.greyscale[6],
  strokeWidth = 0.95,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={'calendar-birthday-contained icon ' + className}
    >
      <path
        d='M9.66536 1.33333V3.99999'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.33333 1.33333V3.99999'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 5.99999H13'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.6667 2.66666H2.33333C1.59667 2.66666 1 3.26332 1 3.99999V12.6667C1 13.4033 1.59667 14 2.33333 14H11.6667C12.4033 14 13 13.4033 13 12.6667V3.99999C13 3.26332 12.4033 2.66666 11.6667 2.66666Z'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.06641 9.21333L7.03974 8.33333'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.14844 11.6667H7.93511'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.04036 8.33333V11.6667'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
