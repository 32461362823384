import theme from '@styles/theme';

const CircleCheckmark = ({
  width = 20,
  height = 20,
  stroke = theme.palette.greyscale[6],
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    {...props}
  >
    <circle
      cx={9.99922}
      cy={9.99971}
      r={7.50312}
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.03516 10.2824L8.84174 12.089L8.83007 12.0773L12.9059 8.00146'
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
export { CircleCheckmark };
