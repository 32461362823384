export const WeightDiet = ({
  width = 14,
  height = 14,
  stroke = '#202020',
  strokeWidth = 1.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={'weight-diet-icon ' + className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.996094 4.33223V9.66779C0.996094 11.5095 2.4891 13.0025 4.33082 13.0025H9.66637C11.5081 13.0025 13.0011 11.5095 13.0011 9.66779V4.33223C13.0011 2.49052 11.5081 0.997513 9.66637 0.997513H4.33082C2.4891 0.997513 0.996094 2.49052 0.996094 4.33223Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.57283 7.00001H5.42485C4.78875 7.00026 4.24098 6.55132 4.1163 5.92756L3.98292 5.26061C3.90458 4.86868 4.00612 4.46227 4.2596 4.15324C4.51307 3.84421 4.89177 3.66513 5.29146 3.66528H8.70622C9.1059 3.66513 9.4846 3.84421 9.73808 4.15324C9.99156 4.46227 10.0931 4.86868 10.0148 5.26061L9.88137 5.92756C9.7567 6.55132 9.20892 7.00026 8.57283 7.00001Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.33203 7.00001L6.99898 5.66612'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
