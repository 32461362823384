import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';

export class CastingApplicationsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastingApplications;

  public static getProjectListApplications(
    listId: number
  ): Promise<ListApplication[]> {
    const endpoint = `/projectLists/${listId}/applications`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getListApplicationById(
    listApplicationId: number
  ): Promise<ListApplicationDetail> {
    const endpoint = `/applications/${listApplicationId}/`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
