import router from 'next/router';
import { message } from 'antd';
import { useDispatch } from 'react-redux';

import { configureStore } from '@reduxjs/toolkit';

import {
  applicationsApiReducer,
  applicationsApiSlice,
  appReducer,
  bookingsApiReducer,
  bookingsApiSlice,
  bookingsReducer,
  castingSearchApiSlice,
  companiesApiReducer,
  companiesApiSlice,
  galleriesApiReducer,
  galleriesApiSlice,
  projectListsApiReducer,
  projectListsApiSlice,
  projectsApiReducer,
  projectsApiSlice,
  projectsReducer,
  searchApiReducer,
} from './slices';

// combines all exported reducers from slice files
const rootReducer = {
  app: appReducer,
  projects: projectsReducer,
  bookings: bookingsReducer,
  [projectsApiSlice.reducerPath]: projectsApiReducer,
  [projectListsApiSlice.reducerPath]: projectListsApiReducer,
  [companiesApiSlice.reducerPath]: companiesApiReducer,
  [castingSearchApiSlice.reducerPath]: searchApiReducer,
  [bookingsApiSlice.reducerPath]: bookingsApiReducer,
  [applicationsApiSlice.reducerPath]: applicationsApiReducer,
  [galleriesApiSlice.reducerPath]: galleriesApiReducer,
};

import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import {
  setCurrentListId,
  setCurrentProjectId,
  setSelectedBookingDate,
} from './slices/projects/projectsSlice';

/**
 * Log a warning and show a toast!
 */

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('We got a rejected action!', { action });
      if (action?.payload?.status === 404 || action?.payload?.status === 403) {
        message.error({
          content: 'Something went wrong. Please refresh & try again.',
          key: 'global-error',
        });
        api.dispatch(setCurrentProjectId(null));
        api.dispatch(setCurrentListId(null));
        api.dispatch(setSelectedBookingDate(null));
        router.replace('/');
      }
    }

    return next(action);
  };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      rtkQueryErrorLogger,
      projectsApiSlice.middleware,
      projectListsApiSlice.middleware,
      companiesApiSlice.middleware,
      castingSearchApiSlice.middleware,
      bookingsApiSlice.middleware,
      applicationsApiSlice.middleware,
      galleriesApiSlice.middleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
