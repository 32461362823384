import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  user: {
    profile?: UserDetail;
    impersonatedUser?: number;
  };
  google: {
    loaded?: boolean;
  };
}

const initialState: AppState = {
  user: {
    profile: null,
    impersonatedUser: null,
  },
  google: {
    loaded: false,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<UserDetail>) => {
      state.user.profile = action.payload;
    },
    setGoogleMapsApiLoaded: (state, action: PayloadAction<boolean>) => {
      state.google.loaded = action.payload;
    },
    setImpersonatedUser: (state, action: PayloadAction<number>) => {
      state.user.impersonatedUser = action.payload;
    }
  },
});

// actions to use with dispatch(getBookings())
export const { setUserProfile, setGoogleMapsApiLoaded, setImpersonatedUser } = appSlice.actions;
export const { reducer } = appSlice; // use this to pass to combineReducers in upper levels
