// Import the RTK Query methods from the React-specific entry point
import { CastingApplicationsQueryApi } from '@lib/api/endpoints';
import { mapErrorFromMessage, mapErrorFromResponse } from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery: BaseQueryFn
) => Promise<any>;

const getProjectListApplicationsHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingApplicationsQueryApi.getProjectListApplications(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getListApplicationDetailHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID or DTO provided');
    });
  }
  return CastingApplicationsQueryApi.getListApplicationById(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// Define our single API slice object
const apiSlice = createApi({
  reducerPath: 'applications-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: ['list-applications', 'list-application-detail'],
  endpoints: (builder) => ({
    getProjectListApplications: builder.query<
      ListApplication[],
      string | number
    >({
      queryFn: getProjectListApplicationsHandler,
      providesTags: ['list-applications'],
    }),
    getListApplicationDetailHandler: builder.query<
      ListApplicationDetail,
      string | number
    >({
      queryFn: getListApplicationDetailHandler,
      providesTags: ['list-application-detail'],
    }),
  }),
});

export const {
  useGetProjectListApplicationsQuery,
  useGetListApplicationDetailHandlerQuery,
  reducer,
} = apiSlice; // hook + reducer export

export { apiSlice as applicationsApiSlice }; // slice export
