import { IconProps } from '@lib/types/icon.types';

const VerticalEllipsesIcon = ({
  width = 20,
  height = 20,
  color,
  fill,
}: IconProps) => (
  <svg
    width={width}
    height={height}
    color={color}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.625 12.001C11.625 12.208 11.793 12.376 12 12.374C12.207 12.374 12.375 12.206 12.375 11.999C12.375 11.792 12.207 11.624 12 11.624C11.793 11.625 11.625 11.793 11.625 12.001'
      stroke={'currentColor'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.625 8.00101C11.625 8.20801 11.793 8.37601 12 8.37401C12.207 8.37401 12.375 8.20601 12.375 7.99901C12.375 7.79201 12.207 7.62401 12 7.62401C11.793 7.62501 11.625 7.79301 11.625 8.00101'
      stroke={'currentColor'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.625 16.001C11.625 16.208 11.793 16.376 12 16.374C12.207 16.374 12.375 16.206 12.375 15.999C12.375 15.792 12.207 15.624 12 15.624C11.793 15.625 11.625 15.793 11.625 16.001'
      stroke={'currentColor'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21 7V17C21 19.209 19.209 21 17 21H7C4.791 21 3 19.209 3 17V7C3 4.791 4.791 3 7 3H17C19.209 3 21 4.791 21 7Z'
      stroke={'currentColor'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { VerticalEllipsesIcon };
