import { createContext, useCallback, useContext } from 'react';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@lib/redux.lib/store';

type Props = {
  children: ReactNode;
};

type ContextValue = {
  addBrowseAwayListener: () => void;
  removeBrowseAwayListener: () => void;
};

export const OnboardingContext = createContext({} as ContextValue);

export const OnboardingContextProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const isOnboarded = useSelector(
    (state: RootState) => state.app.user.profile?.onboarded
  );

  const handleBrowseAway = useCallback(() => {
    if (!isOnboarded) {
      router.events.emit('routeChangeError');
      throw 'routeChange aborted.';
    }
  }, [router, isOnboarded]);

  const addBrowseAwayListener = useCallback(
    () => router.events.on('routeChangeStart', handleBrowseAway),
    [router, handleBrowseAway]
  );

  const removeBrowseAwayListener = useCallback(
    () => router.events.off('routeChangeStart', handleBrowseAway),
    [router, handleBrowseAway]
  );

  return (
    <OnboardingContext.Provider
      value={{
        addBrowseAwayListener,
        removeBrowseAwayListener,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext: () => ContextValue = () =>
  useContext<ContextValue>(OnboardingContext);
