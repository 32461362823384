import { InputNumber, InputProps } from 'antd';
import styled from 'styled-components';

type Props = InputProps & { valid?: boolean };

export const NumericInput = styled(InputNumber)<Props>`
  &.ant-input-number {
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '35px')};
    background: ${(props) => props.theme.palette.greyscale[0]};
    font-size: ${(props) => props.theme.palette.textFontSize[6]};
    line-height: ${(props) => props.theme.palette.textLineHeight[4]};
    border: 1px solid
      ${({ theme, required, valid, value }) =>
        valid === false || (required && !value)
          ? theme.palette.color[0]
          : theme.palette.greyscale[3]};
    border-radius: 4px;

    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
      &::placeholder {
        color: ${(props) => props.theme.palette.text[3]};
      }
    }

    &.ant-input-disabled,
    &.ant-input-disabled:hover {
      background: ${(props) => props.theme.palette.greyscale[2]};
      border-color: ${(props) => props.theme.palette.greyscale[3]};
      color: ${(props) => props.theme.palette.text[3]};
    }

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${(props) => props.theme.palette.shadow[1]};
    }

    input {
      font-size: 12px;
    }
  }

  .ant-input-number-wrapper .ant-input-number-group-addon {
    font-size: ${(props) => props.theme.palette.textFontSize[6]};
    color: ${(props) => props.theme.palette.text[0]};
  }
`;
