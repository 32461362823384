import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';

export class CastingBookingsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Bookings;

  public static getAllUserBookings(userId: number): Promise<AllUserBookings[]> {
    const endpoint = `/users/${userId}/bookings/all`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookings(
    projectId: number
  ): Promise<BookingWithUserDetails[]> {
    const endpoint = `/projects/${projectId}/bookings`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingDateIds(
    projectId: number,
    bookingDates: string[]
  ): Promise<BookingDateIds> {
    const buildQueryParams = () =>
      bookingDates?.length
        ? `?booking_dates=[${bookingDates?.map((date) => `"${date}"`)}]`
        : '';
    const endpoint = `/projects/${projectId}/bookings/dates${buildQueryParams()}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getNextBookingDate(unitId: number): Promise<BookingDateIds> {
    const endpoint = `/bookingdates/${unitId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getFutureBookingDates(
    projectId: number,
    bookingDateIds: number[]
  ): Promise<FutureDatesResponse> {
    const endpoint = `/projects/${projectId}/bookings/futureDates?booking_date_ids=[${bookingDateIds}]`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getConflictingBookingDates(
    projectId: number,
    date: string
  ): Promise<ConflictingBookingDatesResponse> {
    const endpoint = `/projects/${projectId}/bookings/conflictingDates?booking_date=${date}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingDateNotes(
    projectId: number,
    bookingDate: string,
    unitId: number
  ): Promise<BookingDateNotes> {
    const endpoint = `/projects/${projectId}/bookings/dates/notes?booking_date=${bookingDate}&unit_id=${unitId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getAllBookingColumns(
    projectId: number
  ): Promise<BookingColumn[]> {
    const endpoint = `/projects/${projectId}/bookings/columns/all`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingColumns(
    projectId: number,
    unitId: number = null,
    unitDate: string = null
  ): Promise<BookingColumn[]> {
    const queryParams = () => {
      if (!unitId && !unitDate) return '';
      return `?unit_date='${unitDate}'&unit_id=${unitId}`;
    };
    const endpoint = `/projects/${projectId}/bookings/columns${queryParams()}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingValues(
    projectId: number,
    bookingDates: string[]
  ): Promise<BookingValue[]> {
    const buildQueryParams = () => {
      const bookingDatesStr = bookingDates
        ?.map((date) => `"${date}"`)
        ?.join(',');
      return bookingDatesStr ? `?booking_dates=[${bookingDatesStr}]` : '';
    };
    const endpoint = `/projects/${projectId}/bookings/values${buildQueryParams()}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingTableRoleGroupSettings(
    booking_date: string,
    unit_id: number
  ): Promise<BookingTableRoleGroupSettings> {
    const endpoint = `/projects/bookings/rolegroupsettings?booking_date=${booking_date}&unit_id=${unit_id}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingTableSortSettings(
    bookingDate: string,
    unitId: number
  ): Promise<BookingTableSortSettings> {
    const endpoint = `/projects/bookings/tablesortsettings?booking_date=${bookingDate}&unit_id=${unitId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getBookingTableFilterSettings(
    bookingDate: string,
    unitId: number
  ): Promise<BookingTableFilterSettingsApiData> {
    const endpoint = `/projects/bookings/tableFilterSettings?booking_date=${bookingDate}&unit_id=${unitId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectBookingUsersWithPhotos(
    projectId: number,
    bookingDateIds: number[],
    bookingDate: string,
    unitId: number
  ): Promise<BookingUserWithPhotos[]> {
    const endpoint = `/projects/${projectId}/bookings/usersWithPhotos?booking_date_ids=[${bookingDateIds}]&booking_date='${bookingDate}'&unit_id=${unitId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static copyToListColumnCheck(
    castingListId: number,
    sourceColumnIds: number[]
  ): Promise<ExistingColumnInTargetListDto[]> {
    const endpoint = `/projectLists/${castingListId}/copyToListColumnCheck?column_ids=${JSON.stringify(
      sourceColumnIds
    )}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
