import theme from '@styles/theme';

export const Plus = ({
  width = 16,
  height = 17,
  stroke = theme.palette.primary[0],
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ transform: 'rotate(45deg)' }}
    className={className + ' plus-icon'}
  >
    <path
      d='M4 4.91669L12 12.9167'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 4.91669L4 12.9167'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
