import type { InputProps } from 'antd';
import InputMask from 'react-input-mask';

import { Input } from './Input';

type Props = InputProps & {
  maskFormat?: string;
};

export const DateTextInput = ({
  maskFormat,
  value,
  onChange,
  placeholder,
  onBlur,
  ...otherProps
}: Props): JSX.Element => {
  return (
    <InputMask
      mask={maskFormat || '99/99/9999'}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      maskChar={null}
      onBlur={onBlur}
    >
      {(inputProps) => <Input {...otherProps} {...inputProps} />}
    </InputMask>
  );
};
