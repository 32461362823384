import { IconProps } from '@lib/types/icon.types';
import theme from '@styles/theme';

export const User = ({
  width = 20,
  height = 20,
  stroke = theme.palette.text[1],
}: IconProps): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
  >
    <path
      d='M20 20V19.25C20 16.9028 18.0972 15 15.75 15H8.25C5.90279 15 4 16.9028 4 19.25V20'
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx={12}
      cy={7}
      r={4}
      stroke={stroke}
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
