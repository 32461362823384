export const GenderMale = ({
  width = 20,
  height = 20,
  stroke = 'currentColor',
  strokeWidth = 0.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 7 10'
    className={'gender-male-icon ' + className}
  >
    <path
      d='M4.41921 2.63133L3.15308 1.37305'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.88696 2.63133L3.1531 1.37305'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <ellipse
      cx='3.15287'
      cy='6.40621'
      rx='2.53227'
      ry='2.51656'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
      fill='none'
    />
    <path
      d='M3.15292 1.37305V3.88961'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
