export const Portrait = ({ width = '200', height = '200', ...otherProps }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 200 200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='200' height='200' fill='#ECECEC' />
      <rect
        x='49.5'
        y='67.5'
        width='101'
        height='67'
        stroke='#BABABA'
        strokeWidth='3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M87 90L62 108.186V124H108H112H138V111L124 98L111.717 107.98L87 90Z'
        fill='#BABABA'
      />
      <circle cx='113' cy='87' r='7' fill='#BABABA' />
    </svg>
  );
};
