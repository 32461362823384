export const Warning = ({
  width = 20,
  height = 20,
  stroke = '#202020',
  strokeWidth = 1.5,
  className = '',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' warning-icon'}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 1V1C14.971 1 19 5.029 19 10V10C19 14.971 14.971 19 10 19V19C5.029 19 1 14.971 1 10V10C1 5.029 5.029 1 10 1Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10 10.5V5.5'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.999 14C9.861 14 9.749 14.112 9.75 14.25C9.75 14.388 9.862 14.5 10 14.5C10.138 14.5 10.25 14.388 10.25 14.25C10.25 14.112 10.138 14 9.999 14'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
