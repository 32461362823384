import { ReactElement } from 'react';
import type { ButtonProps as AntdButtonProps } from 'antd';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

export interface ButtonProps extends AntdButtonProps {
  type?: 'link' | 'primary' | 'text' | 'default' | 'ghost' | 'dashed';
  htmlType?: 'button' | 'reset' | 'submit';
  className?: string;
}

const Button = (props: ButtonProps): ReactElement => {
  const { children, type = 'default', ...rest } = props;

  return (
    <StyledButton type={type} {...rest}>
      {children}
    </StyledButton>
  );
};

const StyledButton = styled(AntButton)`
  &.ant-btn {
    display: inline-flex;
    align-items: center;
    height: ${(props) => (props.type === 'text' ? 'auto' : '35px')};
    padding: 12px 12px;
    vertical-align: middle;
    user-select: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: ${(props) =>
      props?.style
        ? `${props?.style?.fontSize}px`
        : props.theme.palette.textFontSize[7]};
    font-weight: ${(props) =>
      props?.style
        ? props?.style?.fontWeight
        : props.theme.palette.textFontWeight[0]};
    line-height: 21px;

    &.ant-btn-primary {
      box-shadow: none;
    }

    &.ant-btn-default {
      background: ${(props) => props.theme.palette.greyscale[0]};
      border: 1px solid ${(props) => props.theme.palette.border[1]};
      color: ${(props) => props.theme.palette.text[0]};
      box-shadow: none;
      &:hover {
        background: ${(props) => props.theme.palette.greyscale[1]};
      }
    }

    &.ant-btn-text {
      padding: 0;
      background: transparent;
      color: ${(props) => props.theme.palette.primary[0]};
      border: none;

      &:hover {
        color: ${(props) => props.theme.palette.hover[0]};
      }

      &:disabled {
        color: ${(props) => props.theme.palette.primary[13]};
      }
    }

    &:disabled,
    &:disabled:hover,
    &:disabled:focus,
    &:disabled:active {
      opacity: 40%;
      cursor: not-allowed;
    }

    &.ant-btn-loading {
      cursor: not-allowed;
    }
  }
`;

export { Button };
