import { ReactElement } from 'react';
import type { InputProps, InputRef } from 'antd';
import { Input } from 'antd';
import type { MutableRefObject } from 'react';
import styled, { CSSProperties } from 'styled-components';

import { Label } from '../Label';

interface IProps extends InputProps {
  // label props
  label?: string;
  labelStyle?: CSSProperties;
  requiredSymbol?: boolean;

  // input props
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  onChange?: (event) => void;
  style?: CSSProperties;
  innerRef?: MutableRefObject<InputRef>;
}

const PasswordInput = (props: IProps): ReactElement => {
  const { label, labelStyle, requiredSymbol, innerRef, style, ...rest } = props;

  return (
    <>
      {label ? (
        <>
          <Label
            label={label}
            requiredSymbol={requiredSymbol}
            style={labelStyle}
          />
          <br />
        </>
      ) : (
        <></>
      )}
      <StyledInput
        {...rest}
        ref={innerRef}
        style={{
          margin: '8px 0',
          height: 37,
          borderRadius: 4,
          padding: '8px 10px',
          fontSize: 15,
          display: 'flex',
          alignItems: 'center',
          ...style, // override
        }}
      />
    </>
  );
};

const StyledInput = styled(Input.Password)`
  &.ant-input-password {
    margin: 8px 0;
    height: 37px;
    border-radius: 4px;
    padding: 8px 10px;
    font-size: 15px;
    display: flex;
    align-items: center;
  }
`;

export { PasswordInput };
