import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class CastingPermissionsCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Permissions;

  public static createProjectEmployees(
    projectId: number,
    employees: ProjectEmployeeDto[],
    teamIds: number[] = []
  ): Promise<ProjectEmployee[]> {
    let endpoint = `/projects/${projectId}/employees`;
    if (teamIds.length) endpoint += `?team_ids=[${teamIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, employees)
    );
  }

  public static updateProjectEmployees(
    projectId: number,
    employees: UpdateProjectEmployeeDTO[]
  ): Promise<ProjectEmployee[]> {
    const endpoint = `/projects/${projectId}/employees`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, employees)
    );
  }

  public static updateEmployeeNotificationSettings(
    projectId: number,
    updateObjects: UpdateEmployeeNotificationSetting[]
  ): Promise<EmployeeNotificationSetting[]> {
    const endpoint = `/projects/${projectId}/employees/notificationSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, updateObjects)
    );
  }

  public static deleteProjectEmployees(
    projectId: number,
    userIds: number[],
    teamIds: number[] = []
  ): Promise<ProjectEmployee[]> {
    let endpoint = `/projects/${projectId}/employees?user_ids=[${userIds}]`;
    if (teamIds?.length) endpoint += `&team_ids=[${teamIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }
}
