import theme from '@styles/theme';

export const I9Document = ({
  width = 24,
  height = 24,
  className = '',
  stroke = theme.palette.color[2],
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={`i9-document-icon ${className}`}
      {...props}
    >
      <path
        d='M20 7H15.2C14.5376 7 14 6.552 14 6V2M19.3303 5.79333L16.0983 2.65111C15.6697 2.23444 15.088 2 14.4823 2H6.28571C5.02286 2 4 2.99444 4 4.22222V19.7778C4 21.0056 5.02286 22 6.28571 22H17.7143C18.9771 22 20 21.0056 20 19.7778V7.36444C20 6.77556 19.7589 6.21 19.3303 5.79333Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.9564 18.11C12.6697 18.11 12.3797 18.07 12.0864 17.99C11.793 17.91 11.5197 17.79 11.2664 17.63C11.133 17.5633 11.0397 17.47 10.9864 17.35C10.9397 17.2233 10.9264 17.0966 10.9464 16.97C10.9664 16.8433 11.0164 16.7333 11.0964 16.64C11.1764 16.54 11.2764 16.4766 11.3964 16.45C11.523 16.4166 11.6664 16.4366 11.8264 16.51C12.033 16.6233 12.2297 16.7033 12.4164 16.75C12.6097 16.7966 12.7964 16.82 12.9764 16.82C13.3297 16.82 13.6297 16.74 13.8764 16.58C14.123 16.42 14.3064 16.18 14.4264 15.86C14.553 15.5333 14.6164 15.1366 14.6164 14.67V14.02H14.7564C14.703 14.3333 14.593 14.6033 14.4264 14.83C14.2597 15.0566 14.0497 15.23 13.7964 15.35C13.543 15.47 13.263 15.53 12.9564 15.53C12.563 15.53 12.2097 15.4333 11.8964 15.24C11.583 15.04 11.3364 14.7666 11.1564 14.42C10.9764 14.0733 10.8864 13.6833 10.8864 13.25C10.8864 12.7833 10.9864 12.37 11.1864 12.01C11.3864 11.6433 11.6664 11.3566 12.0264 11.15C12.3864 10.9433 12.7997 10.84 13.2664 10.84C13.873 10.84 14.383 10.98 14.7964 11.26C15.2164 11.5333 15.5364 11.9366 15.7564 12.47C15.9764 12.9966 16.0864 13.6333 16.0864 14.38C16.0864 14.9733 16.013 15.5 15.8664 15.96C15.7264 16.42 15.523 16.81 15.2564 17.13C14.9897 17.45 14.6597 17.6933 14.2664 17.86C13.8797 18.0266 13.443 18.11 12.9564 18.11ZM13.3564 14.32C13.563 14.32 13.743 14.2733 13.8964 14.18C14.0564 14.08 14.1797 13.9433 14.2664 13.77C14.353 13.5966 14.3964 13.4 14.3964 13.18C14.3964 12.9533 14.353 12.7533 14.2664 12.58C14.1797 12.4066 14.0564 12.2733 13.8964 12.18C13.743 12.0866 13.563 12.04 13.3564 12.04C13.1497 12.04 12.9697 12.0866 12.8164 12.18C12.663 12.2733 12.5397 12.4066 12.4464 12.58C12.3597 12.7533 12.3164 12.9533 12.3164 13.18C12.3164 13.4 12.3597 13.5966 12.4464 13.77C12.5397 13.9433 12.663 14.08 12.8164 14.18C12.9697 14.2733 13.1497 14.32 13.3564 14.32Z'
        fill={stroke}
      />
      <path
        d='M8.95761 18.09C8.70428 18.09 8.51095 18.02 8.37761 17.88C8.24428 17.74 8.17761 17.5433 8.17761 17.29V11.66C8.17761 11.4066 8.24428 11.21 8.37761 11.07C8.51095 10.93 8.70428 10.86 8.95761 10.86C9.20428 10.86 9.39428 10.93 9.52761 11.07C9.66095 11.21 9.72761 11.4066 9.72761 11.66V17.29C9.72761 17.5433 9.66095 17.74 9.52761 17.88C9.40095 18.02 9.21095 18.09 8.95761 18.09Z'
        fill={stroke}
      />
    </svg>
  );
};
