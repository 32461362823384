import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class CastingCompaniesCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastCompanies;

  public static createCompanies(
    companies: CreateCompany[]
  ): Promise<Company[]> {
    const endpoint = '/companies';
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, companies)
    );
  }

  public static deleteCompanyUsers(
    companyId: number,
    userIds: number[]
  ): Promise<Employee[]> {
    const endpoint = `/companies/${companyId}/users?user_ids=[${userIds}]`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
