export const ROLE_TYPE_SORT_ORDER_MAP = {
  Background: 1,
  'Featured Background': 2,
  'Special Ability': 3,
  'Photo Double': 4,
  'Stand-in': 5,

  // uk options
  'Background Artist': 6,
  'Body Double': 7,
  Double: 8,
  'Featured Artist': 9,
  'Picture Double': 10,
  'Stand In': 11,
  'Supporting Artist': 12,
  'Walk On Artist': 13,
};
