import { Input } from 'antd';
import { TextAreaProps as AntDTextAreaProps } from 'antd/es/input';
import styled from 'styled-components';

type TextAreaProps = AntDTextAreaProps & {
  width?: number;
  height?: number;
  valid?: boolean;
};

const TextAreaInput = styled(Input.TextArea)<TextAreaProps>`
  &.ant-input {
    padding: 10px 8px;
    width: ${(props) => (props.width ? `${props.width}px` : '100%')};

    height: ${(props) => (props.rows ? 'unset' : `${props.height ?? 35}px`)};

    font-size: ${(props) => props.theme.palette.textFontSize[7]};
    line-height: ${(props) => props.theme.palette.textLineHeight[5]};
    color: ${(props) => props.theme.palette.greyscale[7]};
    border: 1px solid
      ${({ theme, required, valid, value }) =>
        valid === false || (required && !value)
          ? theme.palette.color[0]
          : theme.palette.greyscale[3]};
    border-radius: 4px;

    &.ant-input-disabled,
    &.ant-input-disabled:hover {
      background: ${(props) => props.theme.palette.greyscale[2]};
      border-color: ${(props) => props.theme.palette.greyscale[3]};
      color: ${(props) => props.theme.palette.text[3]};
    }

    &::placeholder {
      color: ${(props) => props.theme.palette.greyscale[5]};
    }

    &:hover {
      border-color: ${(props) => props.theme.palette.primary[0]};
    }

    &:focus,
    &:active {
      border-color: ${(props) => props.theme.palette.primary[0]};
      box-shadow: ${({ theme }) => theme.palette.shadow[1]};
    }
  }
`;

export { TextAreaInput };
export default TextAreaInput;
