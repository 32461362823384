const EllipseCamera = ({ width = 35 }) => (
  <svg
    width={width}
    height={width}
    viewBox={'0 0 35 35'}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <ellipse
      fill='#F2F2F2'
      cx='50%'
      cy='50%'
      rx='50%'
      ry='50%'
      stroke={'currentColor'}
      strokeWidth='0'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <symbol id='camera'>
      <path
        d='M13.5415 8.57706L16.4375 6.79194C16.6946 6.63344 17.0173 6.62646 17.2811 6.77368C17.5448 6.92091 17.7082 7.1993 17.7082 7.50132V13.332C17.7082 13.634 17.5448 13.9124 17.2811 14.0596C17.0174 14.2068 16.6947 14.1999 16.4376 14.0414L13.5415 12.2562'
        stroke={'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <rect
        x='2.70801'
        y='5'
        width='10.8333'
        height='10.8333'
        rx='3'
        stroke={'currentColor'}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </symbol>
    <use href='#camera' x='8' y='7' />
  </svg>
);

export { EllipseCamera };
