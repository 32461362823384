import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class CastingProjectsCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastingProjects;

  public static createProject(project: CreateProject): Promise<Project> {
    const endpoint = `/projects`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, project)
    );
  }

  public static removeProjectAttachments(
    projectAttachmentIds: number[]
  ): Promise<ProjectAttachment[]> {
    const endpoint = `/projectAttachments`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.DELETE,
        projectAttachmentIds
      )
    );
  }
  public static createProjectAttachmentTypes(
    projectId: number,
    attachmentTypes: CreateProjectAttachmentType[]
  ): Promise<ProjectAttachmentTypeDetailed[]> {
    const endpoint = `/projects/${projectId}/attachmentTypes`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, attachmentTypes)
    );
  }

  public static removeAttachmentCategory(
    attachmentTypeId: number
  ): Promise<ProjectAttachmentTypeDetailed[]> {
    const endpoint = `/attachmentTypes`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE, [
        attachmentTypeId,
      ])
    );
  }

  public static removeAttachmentsFromCategory(
    attachmentTypeId: number
  ): Promise<ProjectAttachment[]> {
    const endpoint = `/attachmentTypes/${attachmentTypeId}/attachments`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }
  public static updateAttachmentCategoryName(
    attachmentTypeId: number,
    type_name: string
  ): Promise<ProjectAttachmentType> {
    const endpoint = `/attachmentTypes/${attachmentTypeId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, { type_name })
    );
  }
  public static updateProjectAttachmentName(
    projectAttachmentId: number,
    display_name: string
  ): Promise<ProjectAttachmentType> {
    const endpoint = `/projectAttachments/${projectAttachmentId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, { display_name })
    );
  }
  public static createProjectAttachments(
    attachmentTypeId: number,
    createProjectAttachments: CreateProjectAttachment[]
  ): Promise<ProjectAttachment[]> {
    const endpoint = `/attachmentTypes/${attachmentTypeId}/attachments`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        createProjectAttachments
      )
    );
  }

  public static updateProject(
    projectId: number,
    project: UpdateProject
  ): Promise<Project> {
    const endpoint = `/projects/${projectId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, project)
    );
  }

  public static createProjectUnits(
    projectId: number,
    units: ProjectUnitCreate[]
  ): Promise<ProjectUnit[]> {
    const endpoint = `/projects/${projectId}/units`;
    return this.apiProxy.execute(
      new ApiRequest(ApiNames.Projects, endpoint, HttpMethod.POST, units)
    );
  }

  public static updateProjectUnits(
    projectId: number,
    unitDtos: ProjectUnitUpdate[]
  ): Promise<ProjectUnit[]> {
    const endpoint = `/projects/${projectId}/units`;
    return this.apiProxy.execute(
      new ApiRequest(ApiNames.Projects, endpoint, HttpMethod.PUT, unitDtos)
    );
  }

  public static createProjectLocation(
    location: IProjectLocationDTO
  ): Promise<IProjectLocationDAO> {
    const endpoint = `/projectLocations`;
    return this.apiProxy.execute(
      new ApiRequest(
        ApiNames.ProjectLocations,
        endpoint,
        HttpMethod.POST,
        location
      )
    );
  }

  public static updateProjectLocation(
    projectLocationId: number,
    updateLocationDto: IProjectLocationDTO
  ): Promise<IProjectLocationDAO> {
    const endpoint = `/projectLocations/${projectLocationId}`;
    return this.apiProxy.execute(
      new ApiRequest(
        ApiNames.ProjectLocations,
        endpoint,
        HttpMethod.PUT,
        updateLocationDto
      )
    );
  }

  public static deleteProjectLocation(
    projectLocationId: number
  ): Promise<IProjectLocationDAO> {
    const endpoint = `/projectLocations/${projectLocationId}`;
    return this.apiProxy.execute(
      new ApiRequest(ApiNames.ProjectLocations, endpoint, HttpMethod.DELETE)
    );
  }

  public static createProjectContacts(
    projectId: number,
    contacts: ProjectContact[]
  ): Promise<ProjectContact[]> {
    const endpoint = `/projects/${projectId}/contacts`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, contacts)
    );
  }

  public static insertProjectBumps(
    projectId: number,
    projectBumps: CreateProjectBump[]
  ): Promise<ProjectBump[]> {
    const endpoint = `/projects/${projectId}/bumps`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, projectBumps)
    );
  }

  public static updateProjectBumps(
    projectId: number,
    projectBumps: UpdateProjectBump[]
  ): Promise<ProjectBump[]> {
    const endpoint = `/projects/${projectId}/bumps`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, projectBumps)
    );
  }

  public static deleteProjectContacts(
    projectId: number,
    contactIds: number[]
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/contacts?contact_ids=[${contactIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static updateProjectContacts(
    projectId: number,
    projectContacts: ProjectContact[]
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/contacts`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, projectContacts)
    );
  }

  public static upsertProjectNotes(
    projectId: number,
    projectNotes: CreateProjectNotes
  ): Promise<CreateProjectNotes> {
    const endpoint = `/projects/${projectId}/notes`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, projectNotes)
    );
  }

  public static deleteProjectNotes(
    projectId: number,
    projectNoteIds: number[]
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/notes?project_note_ids=[${projectNoteIds}]`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static inviteToRegister(
    projectId: number,
    inviteToRegisterDto: InviteToRegisterDto
  ): Promise<InviteToRegisterResponse> {
    const endpoint = `/projects/${projectId}/inviteToRegister`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        inviteToRegisterDto
      )
    );
  }

  public static deleteSuggestedQuestion(suggestionId: number): Promise<void> {
    const endpoint = `/suggestedQuestions/${suggestionId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  // wardrobe / hmu
  public static createWardrobeHmu(
    projectId: number,
    wardrobeOrHmu: 'wardrobe',
    wardrobeOrHmuDtos: CreateProjectWardrobeHmu[]
  ): Promise<ProjectWardrobe[]>;
  public static createWardrobeHmu(
    projectId: number,
    wardrobeOrHmu: 'hmu',
    wardrobeOrHmuDtos: CreateProjectWardrobeHmu[]
  ): Promise<ProjectHmu[]>;
  public static createWardrobeHmu<T extends ProjectWardrobe | ProjectHmu>(
    projectId: number,
    wardrobeOrHmu: WardrobeOrHmu,
    wardrobeOrHmuDtos: CreateProjectWardrobeHmu[]
  ): Promise<T[]> {
    const endpoint = `/projects/${projectId}/wardrobeHmu?resourceType=${wardrobeOrHmu}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, wardrobeOrHmuDtos)
    );
  }

  public static updateWardrobeHmu(
    wardrobeHmuId: number,
    wardrobeOrHmu: 'wardrobe',
    wardrobeOrHmuDto: UpdateProjectWardrobeHmu
  ): Promise<ProjectWardrobe[]>;
  public static updateWardrobeHmu(
    wardrobeHmuId: number,
    wardrobeOrHmu: 'hmu',
    wardrobeOrHmuDto: UpdateProjectWardrobeHmu
  ): Promise<ProjectHmu[]>;
  public static updateWardrobeHmu<T extends ProjectWardrobe | ProjectHmu>(
    wardrobeHmuId: number,
    wardrobeOrHmu: WardrobeOrHmu,
    wardrobeOrHmuDto: UpdateProjectWardrobeHmu
  ): Promise<T> {
    const endpoint = `/projectWardrobeHmu/${wardrobeHmuId}?resourceType=${wardrobeOrHmu}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, wardrobeOrHmuDto)
    );
  }

  public static deleteWardrobeHmu(
    wardrobeHmuId: number,
    wardrobeOrHmu: WardrobeOrHmu
  ): Promise<number> {
    const endpoint = `/projectWardrobeHmu/${wardrobeHmuId}?resourceType=${wardrobeOrHmu}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static syncUsersWithProductionApp(
    projectId: number,
    syncBookingsDto: SyncBookingsDto
  ): Promise<any> {
    const endpoint = `/projects/${projectId}/syncBookings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, syncBookingsDto)
    );
  }
}
