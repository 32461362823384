const Clock = ({
  stroke = '#494949',
  strokeWidth = '1.5',
  width = 20,
  height = 20,
}: {
  stroke?: string;
  strokeWidth?: string;
  width?: number;
  height?: number;
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.13076 3.07228C10.9566 1.48728 15.3424 3.30478 16.9274 7.13062C18.5124 10.9565 16.6949 15.3423 12.8691 16.9273C9.04326 18.5123 4.65742 16.6948 3.07242 12.869C1.48826 9.04312 3.30492 4.65728 7.13076 3.07228'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.76562 6.65381V10.5296L12.8123 12.3871'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Clock;
