import theme from '@styles/theme';

export const Document = ({
  width = 14,
  height = 14,
  stroke = theme.palette.greyscale[6],
  strokeWidth = 1.5,
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.66797 13H10.3346C11.4392 13 12.3346 12.1046 12.3346 11V4.58111C12.3346 4.05068 12.1239 3.54197 11.7488 3.1669L10.1677 1.58579C9.79266 1.21071 9.28395 1 8.75352 1H3.66797C2.5634 1 1.66797 1.89543 1.66797 3V11C1.66797 12.1046 2.5634 13 3.66797 13Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.66536 10.3333H4.33203'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.66536 7.99998H4.33203'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.33203 5.66668H4.33203'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.3346 4.66667H10.0013C9.26492 4.66667 8.66797 4.06971 8.66797 3.33333V1'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
