export const GenderNonBinary = ({
  width = 20,
  height = 20,
  stroke = 'currentColor',
  strokeWidth = 0.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      viewBox='0 0 11 11'
      width={width}
      height={height}
      className={'gender-non-binary-icon ' + className}
    >
      <path
        d='M3.62422 3.27976L6.53794 1.51109'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.53789 3.27976L3.62417 1.51109'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <ellipse
        cx='5.06254'
        cy='6.78316'
        rx='2.53227'
        ry='2.51656'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M5.06258 0.75V3.75'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
