import { CastingProjectsQueryApi } from '@lib/api/endpoints';
import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

type Props = {
  children: ReactNode;
};

type ContextValue = {
  clientName: string;
  isEveryset: boolean;
  clientLogoUrl: string;
  clientMiniLogoUrl: string;
  clientSupportEmail: string;
  clientSupportPhone: string;
  clientDomain: string;
  jobsUrl: string;
};

const ClientEnvironmentContext = createContext({} as ContextValue);

export const ClientEnvironmentProvider = ({ children }: Props): JSX.Element => {
  const clientName = process.env.NEXT_PUBLIC_CLIENT_NAME || 'Everyset';
  const isEveryset = clientName === 'Everyset';
  const clientLogoUrl = process.env.NEXT_PUBLIC_CLIENT_LOGO_URL;
  const clientMiniLogoUrl = process.env.NEXT_PUBLIC_CLIENT_MINIFIED_LOGO_URL;
  const clientEnvironmentId = process.env.NEXT_PUBLIC_CLIENT_ENVIRONMENT_ID;
  const jobsUrl = process.env.NEXT_PUBLIC_JOBS_PLATFORM_URL;

  const [clientSupportDetails, setClientSupportDetails] =
    useState<{
      clientSupportEmail: string;
      clientSupportPhone: string;
      clientDomain: string;
    }>(null);

  useEffect(() => {
    CastingProjectsQueryApi.getClientEnvironment(+clientEnvironmentId).then(
      (response) => {
        setClientSupportDetails({
          clientSupportEmail: response?.support_email,
          clientSupportPhone: response?.client_phone,
          clientDomain: response?.client_domain,
        });
      }
    );
  }, [clientEnvironmentId]);

  const { clientSupportEmail, clientSupportPhone, clientDomain } =
    clientSupportDetails || {};

  return (
    <ClientEnvironmentContext.Provider
      value={{
        clientName,
        isEveryset,
        clientLogoUrl,
        clientMiniLogoUrl,
        clientSupportEmail,
        clientSupportPhone,
        clientDomain,
        jobsUrl,
      }}
    >
      {children}
    </ClientEnvironmentContext.Provider>
  );
};

export const useClientEnvironmentContext = (): ContextValue =>
  useContext<ContextValue>(ClientEnvironmentContext);
