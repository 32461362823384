import { Auth } from 'aws-amplify';
import { posthog } from 'posthog-js';

import { AuthUserSession } from '@lib/types/authUser.types';

export const refreshTokenIfNeeded = async (
  authUserSession: AuthUserSession
): Promise<void> => {
  let cognitoUser;
  let currentSession;
  let accessToken;
  let refreshToken;
  try {
    cognitoUser = await Auth.currentAuthenticatedUser();
  } catch (err) {
    posthog.capture('tokenRefresh_noCognitoUser', { err, authUserSession });
  }
  try {
    currentSession = await Auth.currentSession();
  } catch (err) {
    posthog.capture('tokenRefresh_noCurrentSession', { err, authUserSession });
  }
  try {
    accessToken = currentSession.getAccessToken();
  } catch (err) {
    posthog.capture('tokenRefresh_noAccessToken', { err, authUserSession });
  }
  try {
    refreshToken = currentSession.getRefreshToken();
  } catch (err) {
    posthog.capture('tokenRefresh_noRefreshToken', { err, authUserSession });
  }

  try {
    const expiration = accessToken.getExpiration();
    const currentTime = Math.floor(Date.now() / 1000);

    const tokenExpiryThreshold = 5 * 60; // 5 minutes in seconds
    if (expiration - currentTime < tokenExpiryThreshold) {
      await cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err || !session) {
          posthog.capture('tokenRefresh_fail', {
            err,
            session,
            expiration,
            currentTime,
            refreshToken,
            accessToken,
            cognitoUser,
          });
          return;
        }
      });
    }
  } catch (error) {
    posthog.capture('tokenRefresh_catchedError', { err: error });
  }
};
