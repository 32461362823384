import type { SVGProps } from 'react';

import theme from '@styles/theme';

export const PictureUploadArrow = ({
  width = 20,
  height = 20,
  stroke = theme.palette.text[1],
  className = '',
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={`${className} picture-upload-arrow-icon`}
  >
    <path
      d='M19.0037 7.99913V15.002C19.0037 17.2121 17.2121 19.0037 15.002 19.0037H4.99788C2.78782 19.0037 0.996216 17.2121 0.996216 15.002V4.99788C0.996216 2.78782 2.78782 0.996216 4.99788 0.996216H11.0004'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M0.996216 11.0005L2.29192 9.70475C2.74425 9.25241 3.35776 8.99829 3.99747 8.99829C4.63716 8.99829 5.25067 9.25241 5.70301 9.70475L9.99997 14.0017'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.00092 19.0038L11.2987 12.706C12.2407 11.764 13.7678 11.764 14.7098 12.706L18.6506 16.6468'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.0025 0.996216L13.5015 3.49726'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.5035 3.49726L16.0025 0.996216'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.0025 0.996216V5.9983'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
