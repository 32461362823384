import { useCallback, useEffect, useState } from 'react';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import { message } from 'antd';
import { Auth, Hub } from 'aws-amplify';
import { usePostHog } from 'posthog-js/react';
import type { Dispatch, SetStateAction } from 'react';

import { HubCapsule } from '@aws-amplify/core';
import { UsersQueryApi } from '@lib/api/endpoints';
import { setUserProfile } from '@lib/redux.lib/slices/app/appSlice';
import type { AppDispatch } from '@lib/redux.lib/store';
import { useAppDispatch } from '@lib/redux.lib/store';
import { AuthUserSession } from '@lib/types/authUser.types';

import { ApiProxy } from '../api/apiProxy';

import { googleSignout } from './googleSignout';
import { AuthListener } from './listener';
import { refreshTokenIfNeeded } from './tokenRefresh';

const setupSession = (
  isCheckingSessionOnMount: boolean,
  setAuthUserSession: (session: AuthUserSession) => void,
  appDispatch: AppDispatch,
  router: NextRouter,
  setLoading: (loading: boolean) => void,
  logout?: (logoutMessage?: string) => void
) => {
  return Auth.currentAuthenticatedUser()
    .then(async (cognitoUser) => {
      if (isCheckingSessionOnMount) setLoading(true);

      const userId =
        cognitoUser.attributes?.['custom:castifi_user_id'] ||
        cognitoUser.signInUserSession.idToken.payload['custom:castifi_user_id'];
      const userData = await UsersQueryApi.getUser(parseInt(userId));
      if (userData?.realm != 'admin' && userData?.realm != 'producer') {
        console.debug('User is not authorized to access this page.');
        logout('You are not authorized to access this page.');
        return;
      }
      appDispatch(setUserProfile(userData));
      if (userData.onboarded === false) {
        await router.push('/onboarding');
      }
      setAuthUserSession(cognitoUser);
    })
    .catch((error) => {
      console.error(error);
    })
    .finally(() => {
      if (isCheckingSessionOnMount) setLoading(false);
    });
};

const takedownSession = async (
  setAuthUserSession: Dispatch<SetStateAction<AuthUserSession>>
) => {
  // TODO
  // clear user_id and user profile that came from DB
  googleSignout();
  await (Auth as any)._storage.clear();
  await (Auth as any).cleanCachedItems();
  ApiProxy.resetToken(); // clears the token
  return setAuthUserSession(null);
};
type UseSessionObject = {
  authUserSession: AuthUserSession;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  logout: () => void;
};

export const useSession = (): UseSessionObject => {
  const [loading, setLoading] = useState<boolean>(true);
  const [authUserSession, setAuthUserSession] = useState<AuthUserSession>(null);
  const appDispatch = useAppDispatch();
  const router = useRouter();
  const posthog = usePostHog();
  const captureAuthEvent = (
    event: 'tokenRefresh' | 'tokenRefresh_failure',
    details: HubCapsule
  ) => {
    posthog.capture(event, details);
  };

  useEffect(() => {
    // initialize listener only once in constructor
    const result = Hub.listen('auth', (data: HubCapsule) =>
      AuthListener(
        data,
        () =>
          setupSession(
            false,
            setAuthUserSession,
            appDispatch,
            router,
            setLoading,
            logout
          ),
        () => takedownSession(setAuthUserSession),
        setLoading,
        captureAuthEvent
      )
    );

    // check for existing session on mount
    setupSession(
      true,
      setAuthUserSession,
      appDispatch,
      router,
      setLoading,
      logout
    );

    // remove listener on takedown - just return listener result that has a remove callback
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const tokenRefreshIntervalId = setInterval(refreshTokenIfNeeded, 60 * 1000);
    return () => clearInterval(tokenRefreshIntervalId);
  }, []);

  // for useCallback React docs say:
  // "every value referenced inside the callback should also appear in the dependencies array."
  const logout = useCallback(
    (logoutMessage?: string) => {
      setLoading(true);
      Auth.signOut()
        .then((success) => {
          if (logoutMessage) {
            message.info(logoutMessage, success);
          } else {
            message.info('successfully signed out', success);
          }

          setLoading(false);
        })
        .catch((err) => {
          console.debug('ERROR', err);
          message.error('Error');
          setLoading(false);
        });
    },
    [setLoading]
  );

  return { authUserSession, loading, setLoading, logout };
};
