const Caret = ({
  direction = 'down',
  width = 16,
  height = 16,
  color = 'currentColor',
  onClick,
  style,
}: {
  direction: 'up' | 'right' | 'down' | 'left';
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    onClick={onClick}
    style={{
      ...style,
      transform:
        direction === 'right'
          ? 'rotate(270deg)'
          : direction === 'up'
          ? 'rotate(180deg)'
          : direction === 'down'
          ? 'rotate(0deg)'
          : 'rotate(90deg)',
    }}
  >
    <path
      d='M13.1315 4.6875H2.86897C2.56116 4.6875 2.38929 5.0125 2.57991 5.23437L7.71116 11.1844C7.85804 11.3547 8.14085 11.3547 8.28929 11.1844L13.4205 5.23437C13.6112 5.0125 13.4393 4.6875 13.1315 4.6875Z'
      fill={color}
    />
  </svg>
);

export { Caret };
