import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class CastingBookingsCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Bookings;

  public static createBookings(
    projectId: number,
    bookings: CreateBooking[]
  ): Promise<Booking[]> {
    const endpoint = `/projects/${projectId}/bookings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, bookings)
    );
  }

  public static updateBookingDates(
    bookingDateDto: UpdateBookingDateDTO
  ): Promise<BookingDate[]> {
    const endpoint = `/projects/bookings/dates`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, bookingDateDto)
    );
  }

  public static updateBookingDatesByBookingIdUnitId(
    projectId: number,
    bookingDateDtos: UpdateBookingDateByBookingIdUnitId[]
  ): Promise<BookingDateUpdateReponse> {
    const endpoint = `/projects/${projectId}/bookings/dates`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, bookingDateDtos)
    );
  }
  public static deleteBookingDates(
    projectId: number,
    bookingDateIds: number[]
  ): Promise<BookingDateUpdateReponse> {
    const endpoint = `/projects/${projectId}/bookings/dates?booking_date_ids=${JSON.stringify(
      bookingDateIds
    )}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE, bookingDateIds)
    );
  }

  public static releaseBookingDates(
    projectId: number,
    bookingDateDto: UpdateBookingDateDTO
  ): Promise<BookingDate[]> {
    const endpoint = `/projects/${projectId}/bookings/releaseDates`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, bookingDateDto)
    );
  }

  public static updateBookingValues(
    projectId: number,
    bookingValueDtos: UpdateBookingValueDTO[]
  ): Promise<BookingDate[]> {
    const normalizeBookingValues = (bookingValues: UpdateBookingValueDTO[]) => {
      const trimValue = (value: unknown) => {
        if (typeof value === 'string') {
          return value.trim();
        }
        if (Array.isArray(value) && value.length) {
          return value.map((v) => (typeof v === 'string' ? v.trim() : v));
        }
        return value;
      };
      return bookingValues.map((bookingValue) => {
        return {
          ...bookingValue,
          values: { value: trimValue(bookingValue.values.value) },
        };
      });
    };

    const endpoint = `/projects/${projectId}/bookings/values`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.PUT,
        normalizeBookingValues(bookingValueDtos)
      )
    );
  }

  public static deleteBookingValues(
    projectId: number,
    bookingValueObjIds: { booking_date_id: number; column_id: number }[]
  ): Promise<DeletedBookingValues> {
    const queryStringParams = JSON.stringify(bookingValueObjIds);
    const endpoint = `/projects/${projectId}/bookings/values?booking_value_dict_ids=${queryStringParams}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static createBookingColumns(
    projectId: number,
    columns: CreateBookingColumn[],
    date?: string,
    unitId?: number
  ): Promise<BookingColumn[]> {
    let endpoint = `/projects/${projectId}/bookings/columns`;
    if (date && unitId) {
      endpoint += `?unit_date=${date}&unit_id=${unitId}`;
    }
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, columns)
    );
  }

  public static updateBookingColumn(
    columnId: number,
    newValues: UpdateBookingColumn
  ): Promise<BookingColumn> {
    const endpoint = `/bookings/columns/${columnId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, newValues)
    );
  }

  public static deleteBookingColumns(columnIds: number[]): Promise<number[]> {
    const endpoint = `/bookings/columns?ids=${JSON.stringify(columnIds)}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static upsertBookingTableRoleGroupSettings(
    dtos: UpsertBookingTableRoleGroupSettings[]
  ): Promise<BookingTableRoleGroupSettings> {
    const endpoint = `/projects/bookings/rolegroupsettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dtos)
    );
  }

  public static upsertBookingTableSortSettings(
    dto: BookingTableSortSettings
  ): Promise<BookingTableSortSettings> {
    const endpoint = `/projects/bookings/tablesortsettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dto)
    );
  }

  public static deleteBookingTableSortSettings(
    bookingDate: string,
    unitId: number
  ): Promise<DeletedBookingTableSortSettings> {
    const endpoint = `/projects/bookings/tablesortsettings?booking_date=${bookingDate}&unit_id=${unitId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static upsertBookingTableFilterSettings(
    dto: BookingTableFilterSettingsApiData
  ): Promise<BookingTableFilterSettingsApiData> {
    const endpoint = `/projects/bookings/tableFilterSettings`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dto)
    );
  }

  public static deleteBookingTableFilterSettings(
    bookingDate: string,
    unitId: number
  ): Promise<DeletedBookingTableSortSettings> {
    const endpoint = `/projects/bookings/tableFilterSettings?booking_date=${bookingDate}&unit_id=${unitId}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.DELETE)
    );
  }

  public static sendMiscMessage(
    projectId: number,
    messageDto: SendMiscMessageDto
  ): Promise<BookingsMiscMessageResponse> {
    const endpoint = `/projects/${projectId}/bookings/sendMisc`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, messageDto)
    );
  }

  public static importMembersToList(
    projectId: number,
    importMembersDto: ImportMembersDto
  ): Promise<void> {
    const endpoint = `/projects/${projectId}/importMembersToBookingDate`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, importMembersDto)
    );
  }

  public static directAddMembersToBookings(
    projectId: number,
    directAddMembersDtos: DirectAddMembersDto[]
  ): Promise<BookingDate[]> {
    const endpoint = `/projects/${projectId}/directAddMembers`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        directAddMembersDtos
      )
    );
  }

  public static updateBookingDatePhotosPositions(
    projectId: number,
    dtos: UpsertBookingDatePhotos[]
  ): Promise<BookingDatePhoto[]> {
    const endpoint = `/projects/${projectId}/bookings/dates/photos`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, dtos)
    );
  }

  public static linkUserToBooking(
    projectId: number,
    dto: LinkBookingUserDto
  ): Promise<LinkBookingUserResponse> {
    const endpoint = `/projects/${projectId}/bookings/linkUser`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dto)
    );
  }

  public static copyBookingDetails(
    projectId: number,
    dto: CopyBookingDetailsDto
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/bookings/dates/copyBookingDetails`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dto)
    );
  }

  public static updateBookingDateNotes(
    projectId: number,
    dto: UpdateBookingDateNotes
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/bookings/dates/notes`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, dto)
    );
  }
  public static mergeColumns(
    projectId: number,
    mergeColumnsDto: MergeBookingColumnsDto
  ): Promise<BookingColumn> {
    const endpoint = `/projects/${projectId}/bookingColumns/merge`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, mergeColumnsDto)
    );
  }

  public static copyMembersToList(
    projectId: number,
    copyMembersToListDto: CopyBookingMembersToListDto
  ): Promise<CopyBookingMembersToListResponse> {
    const endpoint = `/projects/${projectId}/copyToList`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        copyMembersToListDto
      )
    );
  }
}
