import type { SVGProps } from 'react';
const InfoCircle = ({
  width = 20,
  height = 21,
  stroke = '#E14A4A',
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.91806 2.54883V2.54883C14.0261 2.54883 17.3557 5.9264 17.3557 10.0937V10.0937C17.3557 14.2609 14.0261 17.6385 9.91806 17.6385V17.6385C5.81003 17.6385 2.48047 14.2609 2.48047 10.0937V10.0937C2.48047 5.9264 5.81003 2.54883 9.91806 2.54883Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.91711 10.5129V6.32129'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.91628 13.447C9.80224 13.447 9.70968 13.5409 9.71051 13.6566C9.71051 13.7723 9.80307 13.8662 9.91711 13.8662C10.0312 13.8662 10.1237 13.7723 10.1237 13.6566C10.1237 13.5409 10.0312 13.447 9.91628 13.447'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export { InfoCircle };
