export const CircleBlock = ({
  width = 24,
  height = 24,
  stroke = '#F89020',
  className,
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 14 14'
    className={className}
    {...otherProps}
  >
    <g>
      <g>
        <g
          fillRule='evenodd'
          stroke={stroke}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          clipRule='evenodd'
        >
          <path d='M11.407 2.836l-8.273 8.273 8.273-8.273z'></path>
          <path d='M7.27 1.123v0a5.85 5.85 0 00-5.85 5.85v0a5.85 5.85 0 005.85 5.85v0a5.85 5.85 0 005.85-5.85v0a5.85 5.85 0 00-5.85-5.85z'></path>
        </g>
      </g>
    </g>
  </svg>
);
