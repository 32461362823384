import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class CastingSearchQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastingSearch;

  public static getCastingSearchResults(
    searchFilter: SearchFilter
  ): Promise<SearchResponse> {
    const endpoint = `/search`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, searchFilter)
    );
  }
}
