const Attachment = ({
  width = 13,
  height = 13,
  stroke = '#494949',
  strokeWidth = 1.5,
  className = '',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className + ' attachment-icon'}
      {...otherProps}
    >
      <path
        d='M8.92542 7.56265L10.1127 6.37537C11.0556 5.40871 11.0459 3.86363 10.0911 2.90881C9.13627 1.95399 7.59119 1.94436 6.62454 2.8872L5.43726 4.07449'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
      <path
        d='M7.94514 5.05477L5.05469 7.94523'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
      <path
        d='M4.07444 5.43735L2.88715 6.62463C1.9443 7.59129 1.95393 9.13636 2.90876 10.0912C3.86358 11.046 5.40866 11.0556 6.37531 10.1128L7.5626 8.92551'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
export { Attachment };
