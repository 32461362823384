export const CalendarSchedule = ({
  width = 20,
  height = 20,
  stroke = '#494949',
  strokeWidth = 1.5,
  className = '',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' calendar-schedule-icon'}
    {...otherProps}
  >
    <path
      d='M7.49813 2.99622V5.99747'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.5019 2.99622V5.99747'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.3283 10.4161C11.4541 10.1609 11.714 9.99927 11.9985 9.99927C12.2831 9.99927 12.543 10.1609 12.6688 10.4161L13.215 11.5236C13.3234 11.7442 13.5339 11.897 13.7773 11.9318L15.0008 12.1138C15.2821 12.1551 15.5155 12.3524 15.6032 12.6228C15.6909 12.8932 15.6175 13.1899 15.414 13.3884L14.5296 14.2507C14.3531 14.4221 14.2729 14.6697 14.3155 14.912L14.5236 16.1285C14.572 16.4088 14.457 16.6922 14.2269 16.8594C13.9968 17.0266 13.6917 17.0485 13.4401 16.9158L12.3477 16.3416C12.1289 16.2247 11.8662 16.2247 11.6474 16.3416L10.5559 16.9158C10.3043 17.0485 9.99924 17.0266 9.76916 16.8594C9.53908 16.6922 9.42403 16.4088 9.47249 16.1285L9.68057 14.912C9.72265 14.6698 9.64255 14.4223 9.46648 14.2507L8.58612 13.3884C8.3826 13.1901 8.30914 12.8935 8.39657 12.6232C8.484 12.3528 8.7172 12.1554 8.99829 12.1138L10.2208 11.9318C10.4642 11.897 10.6747 11.7442 10.783 11.5236L11.3283 10.4161Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect
      x='2.99625'
      y='4.49683'
      width='18.0075'
      height='16.5069'
      rx='3'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
