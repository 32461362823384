import { ReactElement } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
const { Text } = Typography;

interface IProps {
  label: string;
  requiredSymbol?: boolean;
  style?: React.CSSProperties;
}

const Label = ({
  label,
  requiredSymbol,
  ...otherProps
}: IProps): ReactElement => (
  <StyledText {...otherProps}>
    {label}{' '}
    {requiredSymbol ? <span className='required-symbol'>*</span> : <></>}
  </StyledText>
);

const StyledText = styled(Text)`
  display: inline-block;
  .required-symbol {
    color: ${(props) => props?.theme?.palette?.color?.[0]};
  }
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  margin-bottom: 8px;
`;

export { Label };
