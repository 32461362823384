export const ETHNICITY_OPTIONS = [
  {
    group: 'Asian',
    options: ['East Asian', 'Southeast Asian', 'South Asian / East Indian'],
  },
  {
    group: 'White',
    options: ['Caucasian', 'Eastern European'],
  },
  {
    group: 'Mediterranean',
  },
  {
    group: 'Middle Eastern',
  },
  {
    group: 'Black',
    options: ['African American', 'African Descent', 'Caribbean / West Indies'],
  },
  {
    group: 'Pacific Islander / Hawaiian',
  },
  {
    group: 'Native American / Alaskan',
  },
  {
    group: 'South American',
  },
  {
    group: 'Hispanic / Latinx',
  },
  {
    group: 'Ethnically Ambiguous',
  },
];

export const UK_ETHNICITY_OPTIONS = [
  {
    group: 'African',
    options: [
      'African - Central',
      'African - Eastern',
      'African - Southern',
      'African - Western',
      'North African',
    ],
  },
  {
    group: 'Afro Caribbean / West Indies',
  },
  {
    group: 'Australasia',
  },
  {
    group: 'Pacific Islander / Hawaiian',
  },
  {
    group: 'Asian',
    options: ['Central Asia', 'East Asian', 'South Asian / East Indian'],
  },
  {
    group: 'European',
    options: [
      'Eastern European',
      'Northern European',
      'Mediterranean',
      'Scandinavian',
    ],
  },
  {
    group: 'Middle Eastern',
  },
  {
    group: 'North American',
  },
  {
    group: 'South American',
  },
  {
    group: 'Latin American',
  },
  {
    group: 'Mixed Race',
  },
];

export const ETHNICITY_ABBREV = {
  'Ethnically Ambiguous': 'Amb',
  'Hispanic / Latinx': 'Hisp',
  'South American': 'SAm',
  'Pacific Islander / Hawaiian': 'PI',
  'Native American / Alaskan': 'NA',
  'African American': 'AA',
  'African Descent': 'Af',
  'Caribbean / West Indies': 'Car',
  'Middle Eastern': 'ME',
  Mediterranean: 'Med',
  Caucasian: 'C',
  'Eastern European': 'Eu',
  'East Asian': 'EA',
  'Southeast Asian': 'SEA',
  'South Asian / East Indian': 'EI',

  // uk values
  'African - Central': 'AC',
  'African - Eastern': 'AE',
  'African - Southern': 'AS',
  'African - Western': 'AW',
  'Afro Caribbean / West Indies': 'WI',
  Australasia: 'AuA', // missing from order
  'Central Asia': 'CA',
  'Latin American': 'LA',
  'Mixed Race': 'Mix',
  'North African': 'NAfr',
  'North American': 'NAm',
  'Northern European': 'NEu',
  Scandinavian: 'Sc',
};

export const UK_ETHNICITIES = [
  'African - Central',
  'African - Eastern',
  'African - Southern',
  'African - Western',
  'Afro Caribbean / West Indies',
  'Australasia',
  'Central Asia',
  'East Asian',
  'Eastern European',
  'Latin American',
  'Mediterranean',
  'Middle Eastern',
  'Mixed Race',
  'North African',
  'North American',
  'Northern European',
  'Pacific Islander / Hawaiian',
  'Scandinavian',
  'South American',
  'South Asian / East Indian',
];

// ETHNICITY_SORT_ORDER_MAP also exists on casting-galleries service
export const ETHNICITY_SORT_ORDER_MAP = {
  'African - Central': 1,
  'African - Eastern': 2,
  'African - Southern': 3,
  'African - Western': 4,
  'African American': 5,
  'African Descent': 6,
  'Afro Caribbean / West Indies': 7,
  Australasia: 8,
  'Caribbean / West Indies': 9,
  Caucasian: 10,
  'Central Asia': 11,
  'East Asian': 12,
  'Eastern European': 13,
  'Ethnically Ambiguous': 14,
  'Hispanic / Latinx': 15,
  'Latin American': 16,
  Mediterranean: 17,
  'Middle Eastern': 18,
  'Mixed Race': 19,
  'Native American / Alaskan': 20,
  'North African': 21,
  'North American': 22,
  'Northern European': 23,
  'Pacific Islander / Hawaiian': 24,
  Scandinavian: 25,
  'South American': 26,
  'South Asian / East Indian': 27,
  'Southeast Asian': 28,
};
