export const GenderFemale = ({
  width = 20,
  height = 20,
  stroke = 'currentColor',
  strokeWidth = 0.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 7 10'
      className={'gender-female-icon ' + className}
    >
      <ellipse
        cx='3.18705'
        cy='3.5064'
        rx='2.53227'
        ry='2.52495'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path
        d='M3.1871 8.5562V6.03125'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.92114 7.71432H4.45341'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
