import React from 'react';

export const GenderTransgender = ({
  width = '16',
  height = '16',
  stroke = '#424242',
  strokeWidth = '1.1',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    fill='none'
    viewBox='0 0 16 16'
    {...otherProps}
  >
    <path
      stroke={stroke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
      d='M8 10a3 3 0 100-5.998 3 3 0 000 5.999zm0 0v4.8M6 12.67h4m3.515-8.353v-2.83m0 0h-2.83m2.83 0L10.12 4.881m-7.637-.875V1.485h2.522m-2.52.001L5.88 4.88M5.595 2.9L3.898 4.596'
    />
  </svg>
);
