import type { SVGProps } from 'react';

export const EyeIcon = ({
  width = 20,
  height = 20,
  stroke = '#323232',
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...otherProps}
  >
    <path
      d='M12.122 5.87999C13.293 7.05099 13.293 8.95199 12.122 10.125C10.951 11.296 9.05 11.296 7.877 10.125C6.706 8.95399 6.706 7.05299 7.877 5.87999C9.05 4.70699 10.95 4.70699 12.122 5.87999'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 8C1 7.341 1.152 6.689 1.446 6.088V6.088C2.961 2.991 6.309 1 10 1C13.691 1 17.039 2.991 18.554 6.088V6.088C18.848 6.689 19 7.341 19 8C19 8.659 18.848 9.311 18.554 9.912V9.912C17.039 13.009 13.691 15 10 15C6.309 15 2.961 13.009 1.446 9.912V9.912C1.152 9.311 1 8.659 1 8Z'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
