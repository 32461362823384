import theme from '@styles/theme';

export const LIST_STATUS_OPTIONS = [
  {
    label: 'Invited',
    value: 'invited',
  },
  {
    label: 'Available',
    value: 'submitted',
  },
  {
    label: 'Unavailable',
    value: 'unavailable',
  },
  {
    label: 'Released',
    value: 'released',
  },
  {
    label: 'Denied',
    value: 'denied',
  },
  {
    label: 'Avail Check',
    value: 'available_unconfirmed',
  },
  {
    label: 'Avail Confirmed',
    value: 'first_available',
  },
];

export const LIST_STATUS_MAPPINGS = LIST_STATUS_OPTIONS.reduce(
  (acc, { value, label }) => {
    acc[value] = label;
    return acc;
  },
  {
    delete_from_list: 'Deleted',
  } as Record<string, string>
);

export const LIST_STATUSES = [
  'invited',
  'submitted', // Available
  'unavailable',
  'released', // temporarily "changed_status" until sc-35364
  'denied',
  'available_unconfirmed', // Avail Check
  'first_available', // Avail Conf.
];

export const LIST_STATUS_SORT_ORDER_MAP = {
  first_available: 1, // Avail Conf.
  available_unconfirmed: 2, // Avail Check
  submitted: 3, // Available
  invited: 4,
  released: 5,
  denied: 6,
  unavailable: 7,
};

export const LIST_STATUS_DROPDOWN_SORT_ORDER_MAP = {
  invited: 1,
  submitted: 2, // Available
  unavailable: 3,
  available_unconfirmed: 4, // Avail Check
  first_available: 5, // Avail Conf.
  denied: 6,
  released: 7,
};

const _submitted_style = {
  paletteField: 'color',
  backgroundColorIndex: 10,
  borderIndex: 10,
};
const _invited_style = {
  paletteField: 'color',
  backgroundColorIndex: 9,
  borderIndex: 9,
  textColor: theme.palette.text[0],
};
const _released_style = {
  paletteField: 'greyscale',
  backgroundColorIndex: 6,
  borderIndex: 6,
};
const _available_unconfirmed_style = {
  paletteField: 'color',
  backgroundColorIndex: 1,
  borderIndex: 1,
};
const _unavailable_style = {
  paletteField: 'color',
  backgroundColorIndex: 0,
  borderIndex: 0,
};
const _denied_style = {
  paletteField: 'color',
  backgroundColorIndex: 0,
  borderIndex: 0,
};
const _first_available_style = {
  paletteField: 'color',
  backgroundColorIndex: 2,
  borderIndex: 2,
};
const _deleted_style = {
  paletteField: 'color',
  backgroundColorIndex: 12,
  borderIndex: 12,
};

export const LIST_STATUS_FORMATTING = {
  invited: {
    ..._invited_style,
  },
  submitted: {
    ..._submitted_style,
  },
  unavailable: {
    ..._unavailable_style,
  },
  released: {
    ..._released_style,
  },
  denied: {
    ..._denied_style,
  },
  available_unconfirmed: {
    ..._available_unconfirmed_style,
  },
  first_available: {
    ..._first_available_style,
  },
  delete_from_list: {
    ..._deleted_style,
  },
};
