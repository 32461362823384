import { ApiNames, ApiProxy, ApiRequest } from '../../apiProxy';

export class CastingPermissionsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Permissions;

  public static getEmployees(projectId: number): Promise<ProjectEmployee[]> {
    const endpoint = `/projects/${projectId}/employees`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
