import type { ReactNode } from 'react';

import { useAuth } from '@lib/auth';
import { SplashScreen } from '@views/SplashScreen';

import LoginIndex from './login';

// TODO
// add user from redux to use for conditional render
type Props = {
  children: ReactNode;
  routeProtected: boolean;
};

const ProtectRoute = ({ children, routeProtected }: Props): JSX.Element => {
  const { isAuthenticated, loading } = useAuth();

  if (loading) return <SplashScreen />;
  if (!isAuthenticated && routeProtected) return <LoginIndex />;
  return <>{children}</>;
};

export default ProtectRoute;
