import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface BookingsState {
  bookingDates: string[];
}

const initialState: BookingsState = {
  bookingDates: null,
};

// these are the reducers and action creators
// createSlice combines createReducer and createAction
// actions will share same name as reducer keys
export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setBookingDates: (state, action: PayloadAction<string[]>) => {
      state.bookingDates = action.payload;
    },
  },
});

export const { setBookingDates } = bookingsSlice.actions;
export const { reducer } = bookingsSlice; // use this to pass to combineReducers in upper levels
