// Import the RTK Query methods from the React-specific entry point
import {
  CastingCompaniesQueryApi,
  CastingPermissionsCommandApi,
  CastingPermissionsQueryApi,
  CastingProjectsCommandApi,
  CastingProjectsQueryApi,
} from '@lib/api/endpoints';
import {
  PayRatesCommandApi,
  PayRatesQueryApi,
} from '@lib/api/endpoints/payRates';
import { setCurrentProjectId } from '@lib/redux.lib/slices/projects/projectsSlice';
import {
  checkResourceId,
  mapErrorFromMessage,
  mapErrorFromResponse,
} from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery: BaseQueryFn
) => Promise<any>;

const getProjectsHandler: QueryFn = (userId: number) => {
  const idCheck = checkResourceId(userId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getAllProjectsFromUser(userId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectAttachmentTypesHandler: QueryFn = (projectId: number) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectAttachmentTypes(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getCurrentProjectHandler: QueryFn = (projectId: number, api) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectById(projectId)
    .then((response) => {
      api.dispatch(setCurrentProjectId(response?.id));
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getAttachmentPresignedUrlsHandler: QueryFn = async (arg: {
  projectId: number;
  presignedUrlRequests: ProjectAttachmentPresignedUrlRequest[];
}) => {
  try {
    const data = await CastingProjectsQueryApi.getAttachmentPresignedUrls(
      arg.projectId,
      arg.presignedUrlRequests
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const createProjectAttachmentTypesHandler: QueryFn = async (arg: {
  projectId: number;
  attachmentTypes: CreateProjectAttachmentType[];
}) => {
  try {
    const data = await CastingProjectsCommandApi.createProjectAttachmentTypes(
      arg.projectId,
      arg.attachmentTypes
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const syncUsersWithProductionAppHandler: QueryFn = async (arg: {
  projectId: number;
  dto: SyncBookingsDto;
}) => {
  try {
    const data = await CastingProjectsCommandApi.syncUsersWithProductionApp(
      arg.projectId,
      arg.dto
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const removeAllAttachmentsFromCategoryHandler: QueryFn = async (arg: {
  attachmentTypeId: number;
}) => {
  try {
    const data = await CastingProjectsCommandApi.removeAttachmentsFromCategory(
      arg.attachmentTypeId
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const removeAttachmentCategoryHandler: QueryFn = async (arg: {
  attachmentTypeId: number;
}) => {
  try {
    const data = await CastingProjectsCommandApi.removeAttachmentCategory(
      arg.attachmentTypeId
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const updateAttachmentCategoryNameHandler: QueryFn = async (arg: {
  attachmentTypeId: number;
  type_name: string;
}) => {
  try {
    const data = await CastingProjectsCommandApi.updateAttachmentCategoryName(
      arg.attachmentTypeId,
      arg.type_name
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const updateProjectAttachmentNameHandler: QueryFn = async (arg: {
  projectAttachmentId: number;
  display_name: string;
}) => {
  try {
    const data = await CastingProjectsCommandApi.updateProjectAttachmentName(
      arg.projectAttachmentId,
      arg.display_name
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const createProjectAttachmentsHandler: QueryFn = async (arg: {
  attachmentTypeId: number;
  createProjectAttachments: CreateProjectAttachment[];
}) => {
  try {
    const data = await CastingProjectsCommandApi.createProjectAttachments(
      arg.attachmentTypeId,
      arg.createProjectAttachments
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const removeProjectAttachmentsHandler: QueryFn = async (arg: {
  projectAttachmentIds: number[];
}) => {
  try {
    const data = await CastingProjectsCommandApi.removeProjectAttachments(
      arg.projectAttachmentIds
    );
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

const createProjectHandler: QueryFn = (arg: CreateProject) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No project dto provided' };
    });
  }
  return CastingProjectsCommandApi.createProject(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectContactsHandler: QueryFn = (projectId) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectContacts(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectLocationsHandler: QueryFn = (projectId) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectLocations(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createProjectLocationHandler: QueryFn = (arg: {
  location: IGoogleMapsAddressDTO;
}) => {
  if (!arg?.location) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.createProjectLocation(arg.location)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateProjectLocationHandler: QueryFn = (arg: {
  projectLocationId: number;
  updateLocationDto: IProjectLocationDTO;
}) => {
  if (!arg?.projectLocationId || !arg?.updateLocationDto) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No ID and/or Dto provided' };
    });
  }
  return CastingProjectsCommandApi.updateProjectLocation(
    arg.projectLocationId,
    arg.updateLocationDto
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectUnitsHandler: QueryFn = (projectId, opts) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectUnits(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createProjectUnitsHandler: QueryFn = (arg: {
  projectId: number;
  units: ProjectUnitCreate[];
}) => {
  if (!arg?.projectId || !arg?.units?.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.createProjectUnits(arg.projectId, arg.units)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateProjectUnitsHandler: QueryFn = (arg: {
  projectId: number;
  unitDtos: ProjectUnitUpdate[];
}) => {
  if (!arg.unitDtos?.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No dtos provided' };
    });
  }
  return CastingProjectsCommandApi.updateProjectUnits(
    arg.projectId,
    arg.unitDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectCompanyHandler: QueryFn = (companyId: number) => {
  const idCheck = checkResourceId(companyId);
  if (idCheck) return idCheck;
  return CastingCompaniesQueryApi.getCompany(companyId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectEmployeesHandler: QueryFn = (projectId: number) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingPermissionsQueryApi.getEmployees(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectNotesHandler: QueryFn = (arg: {
  projectId: number;
  projectDate: string;
}) => {
  const idCheck = checkResourceId(arg.projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectNotes(arg.projectId, arg.projectDate)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectBumpsHandler: QueryFn = (projectId: number) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return CastingProjectsQueryApi.getProjectBumps(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getDefaultBumpAmountsHandler: QueryFn = () => {
  return CastingProjectsQueryApi.getDefaultBumpAmounts()
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getProjectPayRatesHandler: QueryFn = (projectId) => {
  const idCheck = checkResourceId(projectId);
  if (idCheck) return idCheck;
  return PayRatesQueryApi.getProjectPayRates(projectId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getSyncBookingsHistoryHandler: QueryFn = (unitIds: number[]) => {
  return CastingProjectsQueryApi.getSyncBookingsHistory(unitIds)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// POST
const createProjectContactsHandler: QueryFn = (arg: {
  projectId: number;
  contacts: ProjectContact[];
}) => {
  if (!arg?.projectId || !arg?.contacts) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.createProjectContacts(
    arg.projectId,
    arg.contacts
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createProjectEmployeesHandler: QueryFn = (arg: {
  projectId: number;
  employees: ProjectEmployeeDto[];
  teamIds?: number[];
}) => {
  if (!arg?.projectId || !arg?.employees) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingPermissionsCommandApi.createProjectEmployees(
    arg.projectId,
    arg.employees,
    arg.teamIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const upsertProjectNotesHandler: QueryFn = (arg: {
  projectId: number;
  projectNotes: CreateProjectNotes;
}) => {
  if (!arg?.projectId || !arg?.projectNotes) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.upsertProjectNotes(
    arg.projectId,
    arg.projectNotes
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createProjectBumpsHandler: QueryFn = (arg: {
  projectId: number;
  projectBumps: CreateProjectBump[];
}) => {
  if (!arg?.projectId || !arg?.projectBumps) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.insertProjectBumps(
    arg.projectId,
    arg?.projectBumps
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createDefaultPayRatesHandler: QueryFn = (arg: {
  projectId: number;
  payRateDtos: DefaultPayRateDto[];
}) => {
  if (!arg?.projectId || !arg?.payRateDtos?.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return PayRatesCommandApi.createDefaultPayRate(arg.projectId, arg.payRateDtos)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// PUT
const updateProjectHandler: QueryFn = (arg: {
  projectId: number;
  project: UpdateProject;
}) => {
  if (!arg?.projectId || !arg?.project) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No ID or Dto provided' };
    });
  }
  return CastingProjectsCommandApi.updateProject(arg.projectId, arg.project)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateProjectEmployeesHandler: QueryFn = (arg: {
  projectId: number;
  employees: UpdateProjectEmployeeDTO[];
}) => {
  if (!arg?.projectId || !arg?.employees) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingPermissionsCommandApi.updateProjectEmployees(
    arg.projectId,
    arg.employees
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateProjectContactsHandler: QueryFn = (arg: {
  projectId: number;
  contacts: ProjectContact[];
}) => {
  if (!arg?.projectId || !arg?.contacts) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.updateProjectContacts(
    arg.projectId,
    arg.contacts
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateProjectBumpsHandler: QueryFn = (arg: {
  projectId: number;
  projectBumps: UpdateProjectBump[];
}) => {
  if (!arg?.projectId || !arg?.projectBumps) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.updateProjectBumps(
    arg.projectId,
    arg.projectBumps
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateEmployeeNotificationSettingsHandler: QueryFn = async (arg: {
  projectId: number;
  updateObjects: UpdateEmployeeNotificationSetting[];
}) => {
  try {
    const data =
      await CastingPermissionsCommandApi.updateEmployeeNotificationSettings(
        arg.projectId,
        arg.updateObjects
      );
    return { data };
  } catch (error) {
    return { error };
  }
};

const updateDefaultPayRateHandler: QueryFn = (arg: {
  projectId: number;
  payRateDto: DefaultPayRateDto;
}) => {
  if (!arg?.projectId || !Object.keys(arg?.payRateDto || {}).length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No ID or Dto provided' };
    });
  }
  return PayRatesCommandApi.updateDefaultPayRate(arg.projectId, arg.payRateDto)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// DELETE
const deleteProjectLocationHandler: QueryFn = (projectLocationId: number) => {
  if (!projectLocationId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.deleteProjectLocation(projectLocationId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteProjectContactsHandler: QueryFn = (arg: {
  projectId: number;
  contactIds: number[];
}) => {
  if (!arg?.projectId || !arg?.contactIds) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.deleteProjectContacts(
    arg.projectId,
    arg.contactIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteProjectEmployeesHandler: QueryFn = (arg: {
  projectId: number;
  userIds: number[];
  teamIds: number[];
}) => {
  if (!arg?.projectId || !arg?.userIds) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingPermissionsCommandApi.deleteProjectEmployees(
    arg.projectId,
    arg.userIds,
    arg.teamIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteProjectNotesHandler: QueryFn = (arg: {
  projectId: number;
  projectNoteIds: number[];
}) => {
  if (!arg?.projectId || !arg?.projectNoteIds) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingProjectsCommandApi.deleteProjectNotes(
    arg.projectId,
    arg.projectNoteIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// Define our single API slice object
const apiSlice = createApi({
  reducerPath: 'projects-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: [
    'current-project',
    'projects',
    'project-contacts',
    'project-locations',
    'project-units',
    'project-company',
    'project-employees',
    'project-notes',
    'project-bumps',
    'default-bump-amounts',
    'default-pay-rates',
    'project-attachment-types',
    'syncBookingsHistory',
  ],
  endpoints: (builder) => ({
    // GET
    getCurrentProject: builder.query<ProjectWithAddress, number>({
      queryFn: getCurrentProjectHandler,
      providesTags: ['current-project'],
    }),
    getProjectAttachmentTypes: builder.query<
      ProjectAttachmentTypeDetailed[],
      number
    >({
      queryFn: getProjectAttachmentTypesHandler,
      providesTags: ['project-attachment-types'],
    }),
    getProjects: builder.query<ProjectWithAddress[], number>({
      queryFn: getProjectsHandler,
      providesTags: ['projects'],
    }),
    getProjectContacts: builder.query<ProjectContact[], number>({
      queryFn: getProjectContactsHandler,
      providesTags: ['project-contacts'],
    }),
    getProjectLocations: builder.query<ProjectLocation[], number>({
      queryFn: getProjectLocationsHandler,
      providesTags: ['project-locations'],
    }),
    getProjectUnits: builder.query<ProjectUnit[], number>({
      queryFn: getProjectUnitsHandler,
      providesTags: ['project-units'],
    }),
    getProjectCompany: builder.query<CompanyWithSettings, number>({
      queryFn: getProjectCompanyHandler,
      providesTags: ['project-company'],
    }),
    getProjectEmployees: builder.query<ProjectEmployee[], number>({
      queryFn: getProjectEmployeesHandler,
      providesTags: ['project-employees'],
    }),
    getProjectNotes: builder.query<
      ProjectNotes,
      { projectId: number; projectDate: string }
    >({
      queryFn: getProjectNotesHandler,
      providesTags: ['project-notes'],
    }),
    getProjectBumps: builder.query<ProjectBump[], number>({
      queryFn: getProjectBumpsHandler,
      providesTags: ['project-bumps'],
    }),
    getDefaultBumpAmounts: builder.query<DefaultBumpAmounts, null>({
      queryFn: getDefaultBumpAmountsHandler,
      providesTags: ['default-bump-amounts'],
    }),
    getDefaultPayRates: builder.query<DefaultPayRate[], number>({
      queryFn: getProjectPayRatesHandler,
      providesTags: ['default-pay-rates'],
    }),
    getSyncBookingsHistory: builder.query<SyncBookingsHistory, number[]>({
      queryFn: getSyncBookingsHistoryHandler,
      providesTags: ['syncBookingsHistory'],
    }),

    // POST
    createAttachmentTypes: builder.mutation<
      ProjectAttachmentTypeDetailed[],
      {
        projectId: number;
        attachmentTypes: CreateProjectAttachmentType[];
      }
    >({
      queryFn: createProjectAttachmentTypesHandler,
      invalidatesTags: ['project-attachment-types'],
    }),

    getPresignedUrls: builder.mutation<
      ProjectAttachmentPresignedUrl[],
      {
        projectId: number;
        presignedUrlRequests: ProjectAttachmentPresignedUrlRequest[];
      }
    >({
      queryFn: getAttachmentPresignedUrlsHandler,
    }),
    updateAttachmentCategoryName: builder.mutation<
      ProjectAttachmentType,
      { attachmentTypeId: number; type_name: string }
    >({
      queryFn: updateAttachmentCategoryNameHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    updateProjectAttachmentName: builder.mutation<
      ProjectAttachment,
      { projectAttachmentId: number; display_name: string }
    >({
      queryFn: updateProjectAttachmentNameHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    createProjectAttachments: builder.mutation<
      ProjectAttachment[],
      {
        attachmentTypeId: number;
        createProjectAttachments: CreateProjectAttachment[];
      }
    >({
      queryFn: createProjectAttachmentsHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    removeProjectAttachmentsFromCategory: builder.mutation<
      ProjectAttachment[],
      { attachmentTypeId: number }
    >({
      queryFn: removeAllAttachmentsFromCategoryHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    removeAttachmentCategory: builder.mutation<
      ProjectAttachmentTypeDetailed[],
      { attachmentTypeId: number }
    >({
      queryFn: removeAttachmentCategoryHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    removeProjectAttachments: builder.mutation<
      ProjectAttachment[],
      { projectAttachmentIds: number[] }
    >({
      queryFn: removeProjectAttachmentsHandler,
      invalidatesTags: ['project-attachment-types'],
    }),
    createProject: builder.mutation<ProjectWithAddress, CreateProject>({
      queryFn: createProjectHandler,
      invalidatesTags: ['projects', 'current-project'],
    }),
    createProjectLocation: builder.mutation<
      ProjectLocation,
      {
        location: IGoogleMapsAddressDTO;
      }
    >({
      queryFn: createProjectLocationHandler,
      invalidatesTags: ['projects', 'project-locations', 'current-project'],
    }),
    createProjectUnits: builder.mutation<
      ProjectUnit[],
      { projectId: number; units: ProjectUnitCreate[] }
    >({
      queryFn: createProjectUnitsHandler,
      invalidatesTags: ['project-units'],
    }),
    createProjectContacts: builder.mutation<
      undefined,
      {
        projectId: number;
        contacts: ProjectContact[];
      }
    >({
      queryFn: createProjectContactsHandler,
      invalidatesTags: ['project-contacts'],
    }),
    createProjectEmployees: builder.mutation<
      undefined,
      {
        projectId: number;
        employees: ProjectEmployeeDto[];
        teamIds?: number[];
      }
    >({
      queryFn: createProjectEmployeesHandler,
      invalidatesTags: ['project-employees'],
    }),
    upsertProjectNotes: builder.mutation<
      ProjectNotes,
      { projectId: number; projectNotes: CreateProjectNotes }
    >({
      queryFn: upsertProjectNotesHandler,
      invalidatesTags: ['project-notes'],
    }),
    createProjectBumps: builder.mutation<
      undefined,
      {
        projectId: number;
        projectBumps: CreateProjectBump[];
      }
    >({
      queryFn: createProjectBumpsHandler,
      invalidatesTags: ['project-bumps'],
    }),
    createDefaultPayRates: builder.mutation<
      DefaultPayRate[],
      {
        projectId: number;
        payRateDtos: DefaultPayRateDto[];
      }
    >({
      queryFn: createDefaultPayRatesHandler,
      invalidatesTags: ['default-pay-rates'],
    }),
    syncUsersWithProductionApp: builder.mutation<
      undefined,
      {
        projectId: number;
        dto: SyncBookingsDto;
      }
    >({
      queryFn: syncUsersWithProductionAppHandler,
      invalidatesTags: ['syncBookingsHistory'],
    }),

    // PUT
    updateProject: builder.mutation<number, UpdateProjectDTO>({
      queryFn: updateProjectHandler,
      invalidatesTags: ['projects', 'current-project'],
    }),
    updateProjectContacts: builder.mutation<
      undefined,
      {
        projectId: number;
        contacts: Omit<ProjectContact, 'project_id'>[];
      }
    >({
      queryFn: updateProjectContactsHandler,
      invalidatesTags: ['project-contacts'],
    }),
    updateProjectLocation: builder.mutation<
      number,
      {
        projectLocationId: number;
        updateLocationDto: IGoogleMapsAddressDTO;
      }
    >({
      queryFn: updateProjectLocationHandler,
      invalidatesTags: ['projects', 'project-locations'],
    }),
    updateProjectUnits: builder.mutation<
      ProjectUnit[],
      { projectId: number; unitDtos: ProjectUnitUpdate[] }
    >({
      queryFn: updateProjectUnitsHandler,
      invalidatesTags: ['project-units'],
    }),
    updateProjectEmployees: builder.mutation<
      number,
      { projectId: number; employees: UpdateProjectEmployeeDTO[] }
    >({
      queryFn: updateProjectEmployeesHandler,
      invalidatesTags: ['project-employees'],
    }),
    updateProjectBumps: builder.mutation<
      number,
      { projectId: number; projectBumps: UpdateProjectBump[] }
    >({
      queryFn: updateProjectBumpsHandler,
      invalidatesTags: ['project-bumps'],
    }),
    updateEmployeeNotificationSettings: builder.mutation<
      EmployeeNotificationSetting[],
      {
        projectId: number;
        updateObjects: UpdateEmployeeNotificationSetting[];
      }
    >({
      queryFn: updateEmployeeNotificationSettingsHandler,
      invalidatesTags: ['project-employees'],
    }),
    updateDefaultPayRate: builder.mutation<
      DefaultPayRate[],
      {
        projectId: number;
        payRateDto: DefaultPayRateDto;
      }
    >({
      queryFn: updateDefaultPayRateHandler,
      invalidatesTags: ['default-pay-rates'],
    }),

    // DELETE
    deleteProjectLocation: builder.mutation<ProjectLocation[], number>({
      queryFn: deleteProjectLocationHandler,
      invalidatesTags: ['projects', 'project-locations'],
    }),
    deleteProjectContacts: builder.mutation<
      DeletedContacts,
      { projectId: number; contactIds: number[] }
    >({
      queryFn: deleteProjectContactsHandler,
      invalidatesTags: ['project-contacts'],
    }),
    deleteProjectEmployees: builder.mutation<
      ProjectEmployee[],
      { projectId: number; userIds: number[]; teamIds?: number[] }
    >({
      queryFn: deleteProjectEmployeesHandler,
      invalidatesTags: ['project-employees'],
    }),
    deleteProjectNotes: builder.mutation<
      DeletedProjectNotes,
      { projectId: number; projectNoteIds?: number[] }
    >({
      queryFn: deleteProjectNotesHandler,
      invalidatesTags: ['project-notes'],
    }),
  }),
});

export const {
  useGetCurrentProjectQuery,
  useGetProjectAttachmentTypesQuery,
  useGetProjectsQuery,
  useGetProjectContactsQuery,
  useGetProjectLocationsQuery,
  useGetProjectUnitsQuery,
  useGetProjectCompanyQuery,
  useGetProjectEmployeesQuery,
  useGetProjectNotesQuery,
  useGetProjectBumpsQuery,
  useGetDefaultBumpAmountsQuery,
  useGetDefaultPayRatesQuery,
  useCreateProjectMutation,
  useCreateProjectLocationMutation,
  useCreateProjectUnitsMutation,
  useCreateProjectContactsMutation,
  useCreateProjectEmployeesMutation,
  useCreateDefaultPayRatesMutation,
  useUpsertProjectNotesMutation,
  useCreateProjectBumpsMutation,
  useUpdateProjectMutation,
  useUpdateProjectContactsMutation,
  useUpdateProjectLocationMutation,
  useUpdateProjectUnitsMutation,
  useUpdateProjectEmployeesMutation,
  useUpdateProjectBumpsMutation,
  useUpdateEmployeeNotificationSettingsMutation,
  useUpdateDefaultPayRateMutation,
  useDeleteProjectLocationMutation,
  useDeleteProjectContactsMutation,
  useDeleteProjectEmployeesMutation,
  useDeleteProjectNotesMutation,
  useGetPresignedUrlsMutation,
  useCreateProjectAttachmentsMutation,
  useRemoveProjectAttachmentsMutation,
  useCreateAttachmentTypesMutation,
  useRemoveProjectAttachmentsFromCategoryMutation,
  useRemoveAttachmentCategoryMutation,
  useUpdateAttachmentCategoryNameMutation,
  useUpdateProjectAttachmentNameMutation,
  useGetSyncBookingsHistoryQuery,
  useSyncUsersWithProductionAppMutation,
  reducer,
} = apiSlice; // hook + reducer export

export { apiSlice as projectsApiSlice }; // slice export
