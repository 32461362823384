// Import the RTK Query methods from the React-specific entry point
import {
  CastingListsCommandApi,
  CastingListsQueryApi,
} from '@lib/api/endpoints';
import { mapErrorFromMessage, mapErrorFromResponse } from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery: BaseQueryFn
) => Promise<any>;

const getCastingListsHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getCastingListsByProject(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const deleteCastingListsHandler: QueryFn = (arg: number[]) => {
  if (!arg || arg.length == 0) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.deleteCastingLists(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const createCastingListsHandler: QueryFn = (arg: CreateCastingList[]) => {
  if (!arg || arg.length == 0) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.createCastingLists(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const updateCastingListsHandler: QueryFn = (arg: {
  castingListId: number;
  castingListUpdate: UpdateCastingList;
}) => {
  if (!arg.castingListId || !arg.castingListUpdate) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.updateCastingList(
    arg.castingListId,
    arg.castingListUpdate
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const archiveCastingListsHandler: QueryFn = async ({
  castingListId,
  is_archived = true,
}: {
  castingListId: number;
  is_archived?: boolean;
}) => {
  if (!castingListId || typeof is_archived !== 'boolean') {
    return mapErrorFromMessage('No ID provided');
  }

  try {
    const response = await CastingListsCommandApi.archiveCastingList(
      castingListId,
      is_archived
    );
    return { data: response };
  } catch (err) {
    return mapErrorFromResponse(err);
  }
};

const updateCastingListMembersHandler: QueryFn = (arg: {
  castingListId: number;
  updateListMemberDtos: UpdateCastingListMember[];
}) => {
  if (
    !arg?.castingListId ||
    !arg?.updateListMemberDtos ||
    !arg?.updateListMemberDtos?.length
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No IDs or Dto provided' };
    });
  }
  return CastingListsCommandApi.updateListMembers(
    arg.castingListId,
    arg.updateListMemberDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateCastingListMembersBulkHandler: QueryFn = (arg: {
  castingListId: number;
  listMemberIds: number[];
  castingListMemberUpdate: UpdateCastingListMemberBulk;
  notificationPreferences?: NotificationPreferences;
}) => {
  if (
    !arg?.castingListId ||
    !arg?.listMemberIds?.length ||
    !arg?.castingListMemberUpdate
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No IDs or Dto provided' };
    });
  }
  return CastingListsCommandApi.updateListMembersBulk(
    arg.castingListId,
    arg.listMemberIds,
    arg.castingListMemberUpdate,
    arg.notificationPreferences
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const addMembersToCastingListHandler: QueryFn = async (arg: {
  castingListId: number;
  members: CreateListUser[];
  list_status?: string;
  applicationId?: number;
  notificationPreferences?: NotificationPreferences;
}) => {
  if (!arg?.castingListId || !arg?.members?.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch(() => {
      return { error: 'No casting list of user IDs provided' };
    });
  }
  try {
    const result = await CastingListsCommandApi.addMembersToCastingList(
      arg.castingListId,
      arg.members,
      arg?.applicationId,
      arg.notificationPreferences
    );
    return { data: result };
  } catch (error) {
    return error;
  }
};

const deleteCastingListMembersHandler: QueryFn = (arg: {
  listMembershipIds: number[];
  projectId: number;
}) => {
  return CastingListsCommandApi.deleteListMembers(
    arg.listMembershipIds,
    arg.projectId
  )
    .then((response) => {
      return { data: response };
    })
    .catch((error) => {
      return { error: { status: 500, statusText: error } };
    });
};

const updateCastingListValuesHandler: QueryFn = (arg: {
  castingListId: number;
  listValueDtos: UpdateCastingListValue[];
}) => {
  if (!arg?.castingListId || !arg?.listValueDtos?.length) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return { error: 'No ID or dto provided' };
    });
  }
  return CastingListsCommandApi.updateCastingListValues(
    arg.castingListId,
    arg.listValueDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateUnitDatesHandler: QueryFn = (arg: {
  castingListId: number;
  unitDatesDtos: UpdateUnitDates[];
}) => {
  if (
    !arg.castingListId ||
    !arg?.unitDatesDtos ||
    !arg?.unitDatesDtos?.length
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.updateUnitDates(
    arg.castingListId,
    arg.unitDatesDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getCastingListColumnsHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getCastingListColumns(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const getCastingListValuesHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getCastingListValues(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const getProjectListUsersHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getProjectListUsers(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const createCastingListColumnsHandler: QueryFn = (arg: {
  castingListId: number;
  listColumnDtos: CreateCastingListColumn[];
}) => {
  if (
    !arg.castingListId ||
    !arg?.listColumnDtos ||
    !arg?.listColumnDtos?.length
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.createCastingListColumns(
    arg.castingListId,
    arg.listColumnDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const updateCastingListColumnsHandler: QueryFn = (arg: {
  castingListId: number;
  updateListColumnDtos: UpdateCastingListColumn[];
}) => {
  if (
    !arg.castingListId ||
    !arg?.updateListColumnDtos ||
    !arg?.updateListColumnDtos?.length
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.updateCastingListColumns(
    arg.castingListId,
    arg.updateListColumnDtos
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};
const deleteCastingListColumnsHandler: QueryFn = (arg: {
  castingListId: number;
  deleteListColumnIds: number[];
}) => {
  if (
    !arg.castingListId ||
    !arg?.deleteListColumnIds ||
    !arg?.deleteListColumnIds?.length
  ) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.deleteCastingListColumns(
    arg.castingListId,
    arg.deleteListColumnIds
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getListFilterSettingsHandler: QueryFn = (castingListId: number) => {
  if (!castingListId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getListFilterSettings(castingListId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const updateListFilterSettingsHandler: QueryFn = (arg: {
  castingListId: number;
  updateFilterDto: UpdateListFilterSettings;
}) => {
  if (!arg.castingListId || !arg?.updateFilterDto) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.upsertListFilterSettings(
    arg.castingListId,
    arg.updateFilterDto
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteListFilterSettingsHandler: QueryFn = (arg: {
  castingListId: number;
}) => {
  if (!arg.castingListId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.deleteListFilterSettings(arg.castingListId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const getListSortSettingsHandler: QueryFn = (castingListId: number) => {
  if (!castingListId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsQueryApi.getListSortSettings(castingListId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const upsertListSortSettingsHandler: QueryFn = (arg: {
  castingListId: number;
  upsertSortDto: UpsertListSortSettingsDto;
}) => {
  if (!arg.castingListId || !arg?.upsertSortDto) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.upsertListSortSettings(
    arg.castingListId,
    arg.upsertSortDto
  )
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const deleteListSortSettingsHandler: QueryFn = (castingListId: number) => {
  if (!castingListId) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingListsCommandApi.deleteListSortSettings(castingListId)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// Define our single API slice object
const apiSlice = createApi({
  reducerPath: 'project-lists-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: [
    'casting-lists',
    'list-columns',
    'list-column-values',
    'project-list-users',
    'list-table-filter-settings',
    'list-table-sort-settings',
  ],
  endpoints: (builder) => ({
    getCastingListsByProject: builder.query<
      CastingListWithCount[],
      string | number
    >({
      queryFn: getCastingListsHandler,
      providesTags: ['casting-lists'],
    }),
    deleteCastingListByIds: builder.mutation({
      queryFn: deleteCastingListsHandler,
      invalidatesTags: ['casting-lists'],
    }),
    createCastingLists: builder.mutation<CastingList[], CreateCastingList[]>({
      queryFn: createCastingListsHandler,
      invalidatesTags: ['casting-lists', 'list-columns'],
    }),
    updateCastingList: builder.mutation<
      CastingList,
      { castingListId: number; castingListUpdate: UpdateCastingList }
    >({
      queryFn: updateCastingListsHandler,
      invalidatesTags: ['casting-lists'],
    }),
    archiveCastingList: builder.mutation<
      CastingList,
      { castingListId: number; is_archived?: boolean }
    >({
      queryFn: archiveCastingListsHandler,
      invalidatesTags: ['casting-lists'],
    }),
    updateCastingListMembers: builder.mutation<
      CastingList,
      {
        castingListId: number;
        updateListMemberDtos: UpdateCastingListMember[];
      }
    >({
      queryFn: updateCastingListMembersHandler,
      invalidatesTags: ['project-list-users'],
    }),
    updateCastingListMembersBulk: builder.mutation<
      UpdateCastingListMemberBulkResponse,
      {
        castingListId: number;
        listMemberIds: number[];
        castingListMemberUpdate: UpdateCastingListMemberBulk;
        notificationPreferences?: NotificationPreferences;
      }
    >({
      queryFn: updateCastingListMembersBulkHandler,
      invalidatesTags: ['project-list-users'],
    }),
    createCastingListMembers: builder.mutation<
      FullListUser[],
      {
        castingListId: number;
        members: CreateListUser[];
        applicationId?: number;
        notificationPreferences?: NotificationPreferences;
      }
    >({
      queryFn: addMembersToCastingListHandler,
      invalidatesTags: [
        'project-list-users',
        'list-columns',
        'list-column-values',
      ],
    }),
    createCastingListColumns: builder.mutation<
      CastingListColumn[],
      { castingListId: number; listColumnDtos: CreateCastingListColumn[] }
    >({
      queryFn: createCastingListColumnsHandler,
      invalidatesTags: ['list-columns'],
    }),
    updateCastingListColumns: builder.mutation<
      CastingListColumn[],
      { castingListId: number; updateListColumnDtos: UpdateCastingListColumn[] }
    >({
      queryFn: updateCastingListColumnsHandler,
      invalidatesTags: ['list-columns'],
    }),
    deleteCastingListColumns: builder.mutation<
      CastingListColumn[],
      { castingListId: number; deleteListColumnIds: number[] }
    >({
      queryFn: deleteCastingListColumnsHandler,
      invalidatesTags: ['list-columns', 'list-table-filter-settings'],
    }),
    deleteCastingListMembers: builder.mutation<
      DeleteCastingListsResponse,
      {
        listMembershipIds: number[];
        projectId: number;
      }
    >({
      queryFn: deleteCastingListMembersHandler,
      invalidatesTags: [
        'casting-lists',
        'list-column-values',
        'project-list-users',
      ],
    }),
    updateCastingListValues: builder.mutation<
      CastingListValue[],
      { castingListId: number; listValueDtos: UpdateCastingListValue[] }
    >({
      queryFn: updateCastingListValuesHandler,
      invalidatesTags: ['list-column-values'],
    }),
    updateUnitDates: builder.mutation<
      UnitDates[],
      {
        castingListId: number;
        unitDatesDtos: UpdateUnitDates[];
      }
    >({
      queryFn: updateUnitDatesHandler,
      invalidatesTags: ['list-column-values'],
    }),
    deleteCastingListFilterSettings: builder.mutation<
      ListFilterSettings,
      { castingListId: number }
    >({
      queryFn: deleteListFilterSettingsHandler,
      invalidatesTags: ['list-table-filter-settings'],
    }),
    deleteCastingListSortSettings: builder.mutation<ListSortSettings, number>({
      queryFn: deleteListSortSettingsHandler,
      invalidatesTags: ['list-table-sort-settings'],
    }),
    updateCastingListFilterSettings: builder.mutation<
      ListFilterSettings,
      { castingListId: number; updateFilterDto: UpdateListFilterSettings }
    >({
      queryFn: updateListFilterSettingsHandler,
      invalidatesTags: ['list-table-filter-settings'],
    }),
    getCastingListFilterSettings: builder.query<ListFilterSettings, number>({
      queryFn: getListFilterSettingsHandler,
      providesTags: ['list-table-filter-settings'],
    }),
    upsertCastingListSortSettings: builder.mutation<
      ListSortSettings,
      { castingListId: number; upsertSortDto: UpsertListSortSettingsDto }
    >({
      queryFn: upsertListSortSettingsHandler,
      invalidatesTags: ['list-table-sort-settings'],
    }),
    getCastingListSortSettings: builder.query<ListSortSettings, number>({
      queryFn: getListSortSettingsHandler,
      providesTags: ['list-table-sort-settings'],
    }),
    getCastingListColumns: builder.query<CastingListColumn[], string | number>({
      queryFn: getCastingListColumnsHandler,
      providesTags: ['list-columns'],
    }),
    getCastingListValues: builder.query<
      CastingListValueReturn,
      string | number
    >({
      queryFn: getCastingListValuesHandler,
      providesTags: ['list-column-values'],
    }),
    getProjectListUsers: builder.query<FullListUser[], string | number>({
      queryFn: getProjectListUsersHandler,
      providesTags: ['project-list-users'],
    }),
  }),
});

export const {
  useGetCastingListsByProjectQuery,
  useDeleteCastingListByIdsMutation,
  useCreateCastingListsMutation,
  useUpdateCastingListMutation,
  useArchiveCastingListMutation,
  useUpdateCastingListMembersMutation,
  useUpdateCastingListMembersBulkMutation,
  useCreateCastingListMembersMutation,
  useCreateCastingListColumnsMutation,
  useUpdateCastingListColumnsMutation,
  useDeleteCastingListColumnsMutation,
  useDeleteCastingListMembersMutation,
  useUpdateCastingListValuesMutation,
  useUpdateUnitDatesMutation,
  useDeleteCastingListFilterSettingsMutation,
  useUpdateCastingListFilterSettingsMutation,
  useGetCastingListFilterSettingsQuery,
  useDeleteCastingListSortSettingsMutation,
  useUpsertCastingListSortSettingsMutation,
  useGetCastingListSortSettingsQuery,
  useGetCastingListColumnsQuery,
  useGetCastingListValuesQuery,
  useGetProjectListUsersQuery,
  reducer,
} = apiSlice; // hook + reducer export

export { apiSlice as projectListsApiSlice }; // slice export
