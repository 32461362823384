import styled from 'styled-components';

export const EmptyInput = styled.div`
  width: 100%;
  height: 28px;
  border: 1px solid ${(props) => props.theme.palette.border[1]};
  border-radius: 4px;
`;

export const EmptyInputWithHoverEffect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  width: 100%;
  height: 28px;
  opacity: 0;
  transition-property: opacity;
  transition-delay: 0.25s;
  line-height: initial;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(props) => props.theme.palette.border[1]};
    border-radius: 4px;
    opacity: 1;
    &.Dates::before {
      content: 'Dates';
      color: ${(props) => props.theme.palette.greyscale[5]};
    }
    &.Free-text,
    &.Role {
      height: 38px;
    }
    &.Notes::before {
      content: 'Notes';
      color: ${(props) => props.theme.palette.greyscale[5]};
    }
    &.rate-type {
      svg {
        margin-right: 10px !important;
        margin-top: 2px !important;
        opacity: 1 !important;
      }
      &::before {
        content: 'Rate Type';
        margin-right: 8px;
        color: ${(props) => props.theme.palette.greyscale[5]};
      }
    }
    &.rate-note::before {
      content: 'Rate Note';
      color: ${(props) => props.theme.palette.greyscale[5]};
    }
    &.Rate::before {
      content: '$';
      color: ${(props) => props.theme.palette.greyscale[5]};
    }
    &.Role::before {
      content: 'Enter Role';
      color: ${(props) => props.theme.palette.greyscale[5]};
    }
    &.Notes {
      height: 38px;
    }
    &.rate-note {
      height: 38px;
    }
    &.Status {
      width: 72px;
      svg {
        margin-right: 10px !important;
        margin-top: 2px !important;
        opacity: 1 !important;
      }
      &::before {
        content: 'Select';
        margin-right: 8px;
        color: ${(props) => props.theme.palette.greyscale[5]};
      }
    }
    &.Type {
      svg {
        margin-right: 10px !important;
        margin-top: 2px !important;
        opacity: 1 !important;
      }
      &::before {
        content: 'Type';
        margin-right: 8px;
        color: ${(props) => props.theme.palette.greyscale[5]};
      }
    }
    &.type-of-day {
      svg {
        margin-right: 10px !important;
        margin-top: 2px !important;
        opacity: 1 !important;
      }
      &::before {
        content: 'Type of Day';
        margin-right: 8px;
        color: ${(props) => props.theme.palette.greyscale[5]};
      }
    }
  }
`;
