import { Amplify } from 'aws-amplify';

import awsconfig from '../aws-exports';

export default function configureAmplify(): void {
  // triggering a build
  const update = {
    ...awsconfig,
    aws_user_pools_id: process.env.NEXT_PUBLIC_USERPOOL_ID,
    aws_cognito_region: process.env.NEXT_PUBLIC_COGNITO_REGION,
    aws_user_pools_web_client_id: process.env.NEXT_PUBLIC_CLIENT_ID,
    oauth: {
      domain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN,
      redirectSignIn: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.NEXT_PUBLIC_OAUTH_REDIRECT_SIGN_OUT,
      scope: ['email', 'openid', 'profile'],
      responseType: 'code',
      federationTarget: 'COGNITO_USER_POOLS',
    },
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: ['GOOGLE'],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OPTIONAL',
    aws_cognito_mfa_types: ['SMS', 'TOTP'],
    federationTarget: 'COGNITO_USER_POOLS',
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  };
  console.debug('AWS Exports: ', JSON.stringify(update));
  Amplify.configure(update);
  return;
}
