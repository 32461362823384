// Import the RTK Query methods from the React-specific entry point
import { CastingGalleriesQueryApi } from '@lib/api/endpoints';
import { mapErrorFromResponse } from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg?,
  api?: BaseQueryApi,
  extraOptions?: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery?: BaseQueryFn
) => Promise<any>;

const getListGalleryBySlugHandler: QueryFn = async (gallerySlug: string) => {
  try {
    const data = await CastingGalleriesQueryApi.getListGallery(gallerySlug);
    return { data };
  } catch (error) {
    return mapErrorFromResponse(error);
  }
};

// Define our single API slice object
const galleriesApiSlice = createApi({
  reducerPath: 'galleries-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: ['list-gallery'],
  endpoints: (builder) => ({
    // GET
    getListGalleryBySlug: builder.query<PublicListGallery, string>({
      queryFn: getListGalleryBySlugHandler,
      providesTags: ['list-gallery'],
    }),
  }),
});

export const { useGetListGalleryBySlugQuery, reducer } = galleriesApiSlice; // hook + reducer export

export { galleriesApiSlice }; // slice export
