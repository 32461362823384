const BookedCheck = ({
  color = '#FDFDFD',
  width = 15,
  height = 15,
  strokeWidth = 1.40492,
  className = 'booked-check',
}: {
  color?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
  className?: string;
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g filter='url(#filter0_d_9701_2283)'>
        <path
          d='M5.0033 7.85127L7.77282 10.5031L7.75493 10.486L14.0033 4.50311'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_9701_2283'
          x='-2'
          y='0'
          width='23'
          height='23'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='4' />
          <feGaussianBlur stdDeviation='2' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_9701_2283'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_9701_2283'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export { BookedCheck };
