import { API, Auth } from 'aws-amplify';

import type { CognitoUser } from '@aws-amplify/auth';

import type { ApiRequest } from './apiRequest';
import { AuthToken } from './authToken';
import { awsApiConfig } from './aws';
import { HttpMethod } from './httpMethod';

API.configure(awsApiConfig);

export class ApiProxy {
  private static instance: ApiProxy;
  private authToken: AuthToken;
  private refreshTimer;

  public static getInstance(): ApiProxy {
    if (!ApiProxy.instance) ApiProxy.instance = new ApiProxy();
    return ApiProxy.instance;
  }

  public static resetToken(): void {
    if (ApiProxy.instance) ApiProxy.instance.setToken(null);
  }

  private setToken(token?: AuthToken): void {
    this.authToken = token;
    if (token) {
      const timeout = 1000 * (token.expiration - 60) - Date.now();
      if (this.refreshTimer) clearInterval(this.refreshTimer);
      this.refreshTimer = setTimeout(this.refreshTokens.bind(this), timeout);
    } else if (this.refreshTimer) {
      clearInterval(this.refreshTimer);
    }
  }

  private async getToken(): Promise<AuthToken> {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const session = user.getSignInUserSession();
    const token = session.getIdToken();
    const refreshToken = session.getRefreshToken();
    const tokenValue = token.getJwtToken();
    const {
      payload: { exp },
    } = token;
    return new AuthToken(tokenValue, exp, refreshToken);
  }

  private async refreshTokens(): Promise<void> {
    const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
    cognitoUser.refreshSession(this.authToken.refreshToken, (err, session) => {
      if (err || !session) {
        // if there was an error refreshing the session
        Auth.signOut();
        localStorage.removeItem('currentProject');
        this.setToken(); // this clears out the token
        return;
      }
      const { refreshToken } = session;
      const expiration = session.getIdToken().payload.exp;
      const authToken = new AuthToken(
        session.getIdToken().getJwtToken(),
        expiration,
        refreshToken
      );
      this.setToken(authToken);
    });
  }

  private httpCall<S>(type: HttpMethod): ApiCall<S> {
    const HTTP_FUNCTION_MAPPER = {
      [HttpMethod.GET]: API.get,
      [HttpMethod.POST]: API.post,
      [HttpMethod.PUT]: API.put,
      [HttpMethod.DELETE]: API.del,
    };
    return HTTP_FUNCTION_MAPPER[type].bind(API);
  }

  private buildHttpParams(request: ApiRequest): HttpParams {
    return {
      body: request.body,
      headers: {
        ...(request.isPublic
          ? {}
          : { Authorization: `Bearer ${this.authToken?.jwtToken}` }),
        ...request.httpHeaders,
      },
      ...request.miscOptions,
    };
  }

  public async execute<ResourceType>(
    request: ApiRequest
  ): Promise<ResourceType> {
    if (!this.authToken && !request.isPublic) {
      this.setToken(await this.getToken());
    }

    const params = this.buildHttpParams(request);
    const call = this.httpCall<ResourceType>(request.method);
    return call(request.apiName, request.url, params);
  }
}
