import type { SVGProps } from 'react';

export const Flag = ({
  width = 16,
  height = 16,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className + ' flag-icon'}
      {...props}
    >
      <path
        d='M3.33333 14.0001V2.62012'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6654 9.3465V2.6665'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33203 9.3333C3.33203 9.3333 3.91536 8.84863 5.66536 8.84863C7.41536 8.84863 8.58203 9.99997 10.332 9.99997C12.082 9.99997 12.6654 9.34863 12.6654 9.34863'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.33203 2.62133C3.33203 2.62133 3.91536 2 5.66536 2C7.41536 2 8.58203 3.15133 10.332 3.15133C12.082 3.15133 12.6654 2.66667 12.6654 2.66667'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
