import type { HubCapsule } from '@aws-amplify/core';

// need the Hub Listener to detect auth events to trigger rerenders
export const AuthListener = async (
  data: HubCapsule,
  setupSession: (isRefresh?: boolean) => Promise<void>,
  takedownSession: () => Promise<void>,
  setLoading: (loading: boolean) => void,
  captureAuthEvent: (
    event: 'tokenRefresh' | 'tokenRefresh_failure',
    details: HubCapsule
  ) => void
): Promise<void> => {
  const event = data.payload.event;
  console.debug('Auth Event', event);
  if (event !== 'tokenRefresh') setLoading(true);

  switch (event) {
    case 'signIn': {
      console.log('user signed in');
      await setupSession();
      break;
    }
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      await takedownSession();
      break;
    case 'signIn_failure':
      console.log('user sign in failed');
      break;
    case 'signOut_failure':
      console.log('user sign out failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
      break;
    case 'autoSignIn':
      console.log('auto sign in');
      break;
    case 'autoSignIn_failure':
      console.log('auto sign in failed');
      break;
    case 'confirmSignUp':
      console.log('user sign up confirmed');
      break;
    case 'forgotPassword':
      console.log('password recovery initiated');
      break;
    case 'forgotPassword_failure':
      console.log('password recovery failed');
      break;
    case 'forgotPasswordSubmit':
      console.log('password confirmation successful');
      break;
    case 'forgotPasswordSubmit_failure':
      console.log('password confirmation failed');
      break;
    case 'tokenRefresh':
      console.log('refreshing tokens');
      captureAuthEvent('tokenRefresh', data);
      await setupSession();
      break;
    case 'tokenRefresh_failure':
      captureAuthEvent('tokenRefresh_failure', data);
      await takedownSession();
      break;
    default:
      console.log('auth event not handled: ', data?.payload?.event);
      console.debug('full data: ', JSON.stringify(data));
  }
  if (event !== 'tokenRefresh') setLoading(false);
};
