import { ApiNames, ApiProxy, ApiRequest } from '@lib/api/apiProxy';

export class CastingCompaniesQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastCompanies;

  public static getAllCompanies(userId: number): Promise<any> {
    let endpoint = `/companies/all`;
    if (userId) endpoint += `?user_id=${userId}`;
    return this.apiProxy.execute(new ApiRequest(ApiNames.Companies, endpoint));
  }

  public static getCompany(companyId: number): Promise<any> {
    const endpoint = `/companies/${companyId}`;
    return this.apiProxy.execute(new ApiRequest(ApiNames.Companies, endpoint));
  }

  public static getProjectsByUsers(userIds: number[]): Promise<ProjectByUser> {
    const endpoint = `/users/projects?user_ids=[${userIds}]`;
    return this.apiProxy.execute<ProjectByUser>(
      new ApiRequest(this.apiName, endpoint)
    );
  }

  public static getCompaniesByUsers(
    userIds: number[]
  ): Promise<CompaniesByUser> {
    const endpoint = `/users/companies?user_ids=[${userIds}]`;
    return this.apiProxy.execute<CompaniesByUser>(
      new ApiRequest(this.apiName, endpoint)
    );
  }

  public static getUsersByCompanies(
    companyIds: number[]
  ): Promise<EmployeeByCompany> {
    const endpoint = `/companies/users?company_ids=[${companyIds}]`;
    return this.apiProxy.execute<EmployeeByCompany>(
      new ApiRequest(this.apiName, endpoint)
    );
  }
}
