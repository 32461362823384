import { IconProps } from '@lib/types/icon.types';

const FolderCameraIcon = ({
  width = 20,
  height = 20,
  x = 24,
  y = 24,
  color,
  fill,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox='0 0 89 89'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='11.2578'
        y='52.1011'
        width='25.6667'
        height='25.6667'
        rx='1.61538'
        stroke={'currentColor'}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M36.9258 60.6659L44.2919 56.1255C45.0271 55.6722 45.9501 55.6523 46.7043 56.0733C47.4584 56.4944 47.9258 57.2905 47.9258 58.1543V71.7136C47.9258 72.5774 47.4585 73.3735 46.7043 73.7946C45.9501 74.2157 45.0271 74.1957 44.2918 73.7425L36.9258 69.2019'
        stroke={'currentColor'}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M58.9232 77.7676H64.4232C69.4858 77.7676 73.5898 73.6635 73.5898 68.6009V31.4637C73.5898 28.5463 72.4309 25.7484 70.368 23.6855L61.6719 14.9894C59.609 12.9265 56.8111 11.7676 53.8937 11.7676H27.7565C22.6939 11.7676 18.5898 15.8716 18.5898 20.9342V37.4342'
        stroke={'currentColor'}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M73.5047 30.1002H60.7578C57.7202 30.1002 55.2578 27.6378 55.2578 24.6002V11.8535'
        stroke={'currentColor'}
        strokeWidth='4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { FolderCameraIcon };
