import { message } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import { ALL_DEFAULT_UNIT_FORMATS, UNITS_ORDER_MAP } from './constants';

dayjs.extend(isBetween);

// legacy - unmodified
export const validatePassword = (
  password: string,
  additionalChecks: { label: string; value: string }[] = [],
  badPasswords?: {
    [password: string]: boolean | { label: string; value: string };
  }
): boolean | { label: string; value: string } => {
  password = password.toLowerCase().trim();

  const BAD_PASSWORD_MAP = Object.assign({}, badPasswords);
  const pieces = [];

  let BAD_PASSWORD = null;

  for (const addtlValue of additionalChecks) {
    addtlValue.value = addtlValue.value?.toLowerCase().trim();
    if (addtlValue.value) {
      BAD_PASSWORD_MAP[addtlValue?.value] = addtlValue;
      pieces.push(addtlValue);

      const adjusted_value = addtlValue?.value?.replace(/\W/gi, '-');
      const splitValue = adjusted_value ? adjusted_value.split('-') : [];
      for (const sect of splitValue) {
        BAD_PASSWORD_MAP[sect] = addtlValue;
        BAD_PASSWORD_MAP[sect?.replace(/\d/gi, '')] = addtlValue;
        if (sect.length > 2) {
          pieces.push({ ...addtlValue, value: sect });
        }
        if (sect?.replace(/\d/gi, '').length > 2) {
          pieces.push({ ...addtlValue, value: sect?.replace(/\d/gi, '') });
        }
      }
    }
  }

  const passwordPieces = password?.replace(/\d/gi, '-')?.split('-');
  passwordPieces?.forEach((piece) => {
    const foundPiece = pieces.find(
      (subPiece) =>
        subPiece.value.trim().toLowerCase() == piece.trim().toLowerCase() ||
        piece.includes(subPiece.value.trim().toLowerCase())
    );
    if (foundPiece) {
      BAD_PASSWORD = foundPiece;
    }
  });

  const ADJ_PW = password?.replace(/\d/gi, '')?.replace(/\W/gi, '');

  if (BAD_PASSWORD_MAP[password]) {
    BAD_PASSWORD = BAD_PASSWORD_MAP[password];
  }

  if (BAD_PASSWORD_MAP[ADJ_PW]) {
    BAD_PASSWORD = BAD_PASSWORD_MAP[ADJ_PW];
  }

  return BAD_PASSWORD;
};

export const displayError = (ish: string) => {
  message.error(ish.replace(/[\S]* failed with error/, ''), 3);
};

export const getAttributesFromURL = (url: string) => {
  const queryString = url?.split('?')[1];
  const params = queryString?.split('&');
  return params?.reduce((prev, param) => {
    const [key, value] = param?.split('=');
    prev[key] = value;
    return prev;
  }, {});
};

export const sortArray = (property: string, a, b): number =>
  a?.[property]?.toLowerCase() > b?.[property]?.toLowerCase() ||
  a?.[property] === null ||
  !a?.[property]?.length
    ? 1
    : b?.[property]?.toLowerCase() > a?.[property]?.toLowerCase() ||
      a?.[property] !== null ||
      a?.[property]?.length
    ? -1
    : 0;

export const displayInvalidRowError = (): void => {
  message.error(
    'One or more fields are invalid. Please check that all fields are correct and try again.'
  );
};

export const checkResourceId = (
  resourceId: number
): Promise<ErrorForRTK> | null => {
  if (!resourceId || isNaN(resourceId)) {
    return new Promise<ErrorForRTK>((resolve, reject) => reject()).catch(() => {
      return {
        error: {
          data: `Invalid ID provided. Id Received: ${resourceId}`,
          status: 400,
        },
      };
    });
  }
  return null;
};
export const mapErrorFromResponse = (
  issue: Partial<{
    response: {
      data: Record<string, string>;
      status?: number;
    };
  }>
): ErrorForRTK => {
  return {
    error: {
      status: issue.response.status,
      data: issue.response.data,
    },
  };
};
export const mapErrorFromMessage = (msg: string): ErrorForRTK => {
  return {
    error: {
      data: msg,
      status: 400,
    },
  };
};

export const isDateOutsideRange = (
  current: Dayjs,
  start: Date,
  end: Date
): boolean => current && !current.isBetween(start, end, 'day', '[]');

export function safeParseInt(str: string | number): number {
  if (typeof str === 'number') return str;
  return Number.isNaN(parseInt(str)) ? null : parseInt(str);
}

export const sortUnits = (a: string, b: string): -1 | 0 | 1 => {
  // Check if unit is a custom unit
  const isCustomUnitA = !ALL_DEFAULT_UNIT_FORMATS.includes(a);
  const isCustomUnitB = !ALL_DEFAULT_UNIT_FORMATS.includes(b);

  const unitA = isCustomUnitA ? 'Custom' : a;
  const unitB = isCustomUnitB ? 'Custom' : b;

  const sortValA =
    UNITS_ORDER_MAP[unitA] !== undefined
      ? UNITS_ORDER_MAP[unitA]
      : Object.keys(UNITS_ORDER_MAP).length + 1;
  const sortValB =
    UNITS_ORDER_MAP[unitB] !== undefined
      ? UNITS_ORDER_MAP[unitB]
      : Object.keys(UNITS_ORDER_MAP).length + 1;

  return sortValA < sortValB ? -1 : sortValA > sortValB ? 1 : 0;
};

export const sortLocations = (
  a: number,
  b: number,
  locationIdsMap: { [id: number]: number }
): -1 | 0 | 1 => {
  const sortValA =
    locationIdsMap[a] !== undefined
      ? locationIdsMap[a]
      : Object.keys(locationIdsMap).length + 1;
  const sortValB =
    locationIdsMap[b] !== undefined
      ? locationIdsMap[b]
      : Object.keys(locationIdsMap).length + 1;

  return sortValA < sortValB ? -1 : sortValA > sortValB ? 1 : 0;
};

export const getStatus = (
  status: string | null,
  statuses: { text: string; abbrev: string; value: string }[],
  isAbbrev: boolean
): string => {
  const { abbrev } = statuses.find(({ value }) => value === status) || {
    abbrev: '',
  };
  const { text } = statuses.find(({ value }) => value === status) || {
    text: '',
  };

  return isAbbrev ? abbrev : text;
};

export const moveCursorToNextLine = () => {
  const textarea = document.getElementById('edit-text-area');
  const currentPosition = textarea.selectionStart;
  const currentValue = textarea.value;
  const newValue =
    currentValue.substring(0, currentPosition) +
    '\n' +
    currentValue.substring(currentPosition);

  textarea.value = newValue;
  textarea.selectionStart = textarea.selectionEnd = currentPosition + 1;
};

export const handleUrlsWithoutProtocol = (url: string): string => {
  if (url && !url.includes('https')) {
    return `https://${url}`;
  }
  return url;
};

export const capitalizeString = (str: string): string => {
  return str.toUpperCase();
}