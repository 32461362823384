import { ThemeConfig } from 'antd/es/config-provider/context';

import theme from './theme';

const isEveryset = process.env.NEXT_PUBLIC_CLIENT_NAME === 'Everyset';

const antTheme: ThemeConfig = {
  token: {
    colorPrimary: theme.palette.primary[0], // primary color
    ...(isEveryset
      ? {
          colorPrimaryBg: theme.palette.hover[1],
          colorPrimaryBgHover: theme.palette.hover[1],
        }
      : {
          colorPrimaryBg: theme.palette.primary[14],
          colorPrimaryBgHover: theme.palette.hover[0],
        }),
    colorError: theme.palette.error[0],
    colorLink: theme.palette.link[0],
    colorLinkHover: theme.palette.hover[0],
    colorPrimaryBorder: theme.palette.primary[0],
    colorPrimaryBorderHover: theme.palette.primary[0],
    fontFamily:
      "'Inter', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
  },
  components: {
    Button: {
      colorPrimaryHover: theme.palette.hover[0],
    },
  },
};

export default antTheme;
