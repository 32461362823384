import { IconProps } from '@lib/types/icon.types';
import theme from '@styles/theme';

export const UserCircle = ({
  width = 20,
  height = 20,
  stroke = theme.palette.text[1],
}: IconProps): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 127 127'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='63.5' cy='63.5' r='63.5' fill='#ECECEC' />
    <path
      d='M85.1672 85.1667V83.1354C85.1672 76.7784 80.0138 71.625 73.6568 71.625H53.3443C46.9873 71.625 41.8339 76.7784 41.8339 83.1354V85.1667'
      stroke={stroke}
      strokeWidth='4.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='63.4995'
      cy='49.9583'
      r='10.8333'
      stroke={stroke}
      strokeWidth='4.875'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
