export const CircleCancel = ({
  width = 24,
  height = 24,
  fill = '#E14A4A',
  stroke = '#FDFDFD',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <circle cx='12' cy='12' r='12' fill={fill} />
      <path
        d='M8 8L16 16'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 8L8 16'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
