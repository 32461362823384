import { ApiNames, ApiProxy, ApiRequest } from '../../apiProxy';

export class PayRatesQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.PayRates;

  public static getProjectPayRates(
    projectId: number
  ): Promise<DefaultPayRate[]> {
    const endpoint = `/project/${projectId}/defaults/payRate`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
