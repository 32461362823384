import { CastingSearchQueryApi } from '@lib/api/endpoints';
import { mapErrorFromMessage, mapErrorFromResponse } from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery: BaseQueryFn
) => Promise<any>;

const getCastingSearchResultsHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => mapErrorFromMessage('Invalid search query provided'));
  }
  return CastingSearchQueryApi.getCastingSearchResults(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

const apiSlice = createApi({
  reducerPath: 'search-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: ['database-search-results'],
  endpoints: (builder) => ({
    getCastingSearchResults: builder.query<SearchResponse, SearchFilter>({
      queryFn: getCastingSearchResultsHandler,
      providesTags: ['database-search-results'],
    }),
  }),
});

export const { useGetCastingSearchResultsQuery, reducer } = apiSlice;
export { apiSlice as castingSearchApiSlice };
