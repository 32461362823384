import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class CastingGalleriesQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Galleries;

  public static getGalleries(castingListId: number): Promise<Gallery[]> {
    const endpoint = `/projectLists/${castingListId}/galleries`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getGalleryMembers(
    gallerySlug: string
  ): Promise<ListGalleryDetails> {
    const endpoint = `/lists/galleries/${gallerySlug}/members`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getListGallery(
    gallerySlug: string
  ): Promise<PublicListGallery> {
    const endpoint = `/lists/galleries/${gallerySlug}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.GET, {}, {}, {}, true)
    );
  }
}
