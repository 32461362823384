export const Phone = ({
  width = 20,
  height = 20,
  className = '',
  stroke = '#323232',
  strokeWidth = 1.5,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' phone-icon'}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.856 11.144C7.686 9.974 6.804 8.664 6.218 7.335C6.094 7.054 6.167 6.725 6.384 6.508L7.203 5.69C7.874 5.019 7.874 4.07 7.288 3.484L6.114 2.31C5.333 1.529 4.067 1.529 3.286 2.31L2.634 2.962C1.893 3.703 1.584 4.772 1.784 5.832C2.278 8.445 3.796 11.306 6.245 13.755C8.694 16.204 11.555 17.722 14.168 18.216C15.228 18.416 16.297 18.107 17.038 17.366L17.689 16.715C18.47 15.934 18.47 14.668 17.689 13.887L16.516 12.714C15.93 12.128 14.98 12.128 14.395 12.714L13.492 13.618C13.275 13.835 12.946 13.908 12.665 13.784C11.336 13.197 10.026 12.314 8.856 11.144Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
