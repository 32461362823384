import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class CastingProjectsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.CastingProjects;

  public static getAttachmentPresignedUrls(
    projectId: number,
    presignedUrlRequests: ProjectAttachmentPresignedUrlRequest[]
  ): Promise<ProjectAttachmentPresignedUrl[]> {
    const endpoint = `/projects/${projectId}/attachmentsPresignedUrls`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        presignedUrlRequests
      )
    );
  }

  public static createProjectAttachments(
    attachmentTypeId: number,
    createProjectAttachments: CreateProjectAttachment[]
  ): Promise<ProjectAttachment[]> {
    const endpoint = `/attachmentTypes/${attachmentTypeId}/attachments`;
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        endpoint,
        HttpMethod.POST,
        createProjectAttachments
      )
    );
  }

  public static getProjectAttachmentTypes(
    projectId: number
  ): Promise<ProjectAttachmentTypeDetailed> {
    const endpoint = `/projects/${projectId}/attachmentTypes`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectById(projectId: number): Promise<ProjectWithAddress> {
    const endpoint = `/projects/${projectId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getAllProjectsFromUser(
    userId: number
  ): Promise<ReducedProjectData[]> {
    const endpoint = `/projects/all?userId=${userId}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectSettings(
    projectId: number
  ): Promise<ProjectSettings> {
    const endpoint = `/projects/${projectId}/settings`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectContacts(
    projectId: number
  ): Promise<ProjectContact[]> {
    const endpoint = `/projects/${projectId}/contacts`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectBumps(projectId: number): Promise<ProjectBump[]> {
    const endpoint = `/projects/${projectId}/bumps`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectUnits(projectId: number): Promise<ProjectUnit[]> {
    const endpoint = `/projects/${projectId}/units`;
    return this.apiProxy.execute(new ApiRequest(ApiNames.Projects, endpoint));
  }

  public static getProjectLocations(
    projectId: number
  ): Promise<ProjectLocation[]> {
    const endpoint = `/projectLocations/${projectId}/all`;
    return this.apiProxy.execute(
      new ApiRequest(ApiNames.ProjectLocations, endpoint)
    );
  }

  public static getProjectNotes(
    projectId: number,
    projectDate: string
  ): Promise<ProjectNotes> {
    const endpoint = `/projects/${projectId}/notes?project_date=${projectDate}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getDefaultBumpAmounts(): Promise<DefaultBumpAmounts> {
    const endpoint = `/bumps/defaults`;
    return this.apiProxy.execute(new ApiRequest(ApiNames.Timesheets, endpoint));
  }

  public static getProjectQuestionSuggestions(
    projectId: number,
    suggestionType: QuestionSuggestionType | null = null
  ): Promise<QuestionSuggestion[]> {
    const queryParams = () => {
      if (!suggestionType) return '';
      return `?suggestionType=${suggestionType}`;
    };

    const endpoint = `/projects/${projectId}/suggestedQuestions${queryParams()}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  // wardrobe / hmu
  public static getProjectWardrobeHmu(
    projectId: number,
    wardrobeOrHmu: WardrobeOrHmu
  ): Promise<ProjectWardrobe[]>;
  public static getProjectWardrobeHmu(
    projectId: number,
    wardrobeOrHmu: 'hmu'
  ): Promise<ProjectHmu[]>;
  public static getProjectWardrobeHmu<T extends ProjectWardrobe | ProjectHmu>(
    projectId: number,
    wardrobeOrHmu: WardrobeOrHmu
  ): Promise<T[]> {
    const endpoint = `/projects/${projectId}/wardrobeHmu?resourceType=${wardrobeOrHmu}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static async getCastingProductionProjectLinks(
    projectId: number
  ): Promise<number[]> {
    const endpoint = `/projects/${projectId}/projectLinks`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static async getSyncBookingsHistory(
    unitIds: number[]
  ): Promise<SyncBookingsHistory> {
    const endpoint = `/projects/syncBookingsHistory?unit_ids=[${unitIds}]`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getClientEnvironment(
    clientEnvironmentId: number
  ): Promise<ClientEnvironment> {
    const endpoint = `/projects/${clientEnvironmentId}/clientsEnvironment`;
    return this.apiProxy.execute(new ApiRequest(ApiNames.Projects, endpoint));
  }
}
