export const HistoryIcon = ({
  width = 20,
  height = 20,
  stroke = '#323232',
  strokeWidth = 1.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    className={'history-icon ' + className}
  >
    <path
      d='M1.51953 6.99808C2.85962 3.22323 6.53418 0.789233 10.5327 1.02781C14.5313 1.26639 17.8903 4.12006 18.772 8.02747C19.6538 11.9349 17.8457 15.9545 14.337 17.8871C10.8284 19.8197 6.46469 19.1995 3.63341 16.366'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7 12.9994L10.0013 9.99818V4.99609'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.99776 6.99776H0.996094V2.99609'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
