const Checkmark = ({
  width = 16,
  className,
  stroke = '#14984C',
  strokeWidth = 1.40492,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={width}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ cursor: 'pointer' }}
    className={className + ' checkmark-icon'}
    {...props}
  >
    <path
      d='M3.20361 8.37468L6.15777 11.2033L6.13869 11.185L12.8036 4.80331'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { Checkmark };
