import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
export interface ProjectsState {
  projects: Project[];
  currentProject: Project;
  projectId: number;
  currentListId: number;
  selectedDates: string[];
  selectedBookingDate: string;
}

const initialState: ProjectsState = {
  projects: [],
  currentProject: null,
  projectId: null,
  currentListId: null,
  selectedDates: [],
  selectedBookingDate: null,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setCurrentProjectId: (state, action: PayloadAction<number>) => {
      state.projectId = action.payload;
    },
    setCurrentListId: (state, action: PayloadAction<number>) => {
      state.currentListId = action.payload;
    },
    setSelectedDates: (state, action: PayloadAction<string[]>) => {
      state.selectedDates = action.payload;
    },
    setSelectedBookingDate: (state, action: PayloadAction<string>) => {
      state.selectedBookingDate = action.payload;
    },
  },
});

export const {
  setCurrentProjectId,
  setCurrentListId,
  setSelectedDates,
  setSelectedBookingDate,
} = projectsSlice.actions;
export const { reducer } = projectsSlice; // use this to pass to combineReducers in upper levels
