const Trash = ({
  width = 20,
  height = 20,
  className = '',
  stroke,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 21 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ cursor: 'pointer' }}
    className={className + ' trash-icon'}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.1603 18.4311H7.25549C6.27533 18.4311 5.46041 17.6765 5.38523 16.6992L4.58057 6.23853H15.8353L15.0306 16.6992C14.9554 17.6765 14.1405 18.4311 13.1603 18.4311V18.4311Z'
      stroke={stroke || '#494949'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.8775 6.23856H3.53857'
      stroke={stroke || '#494949'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.86318 3.42487H12.5526C13.0706 3.42487 13.4905 3.84477 13.4905 4.36276V6.23854H6.92529V4.36276C6.92529 3.84477 7.3452 3.42487 7.86318 3.42487Z'
      stroke={stroke || '#494949'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.849 9.99011V14.6796'
      stroke={stroke || '#494949'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.56625 9.99011V14.6796'
      stroke={stroke || '#494949'}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { Trash };
