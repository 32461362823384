// Import the RTK Query methods from the React-specific entry point
import { CastingCompaniesQueryApi } from '@lib/api/endpoints';
import { mapErrorFromMessage, mapErrorFromResponse } from '@lib/utils/methods';
import {
  BaseQueryApi,
  BaseQueryExtraOptions,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  BaseQueryFn,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

type QueryFn = (
  arg?,
  api?: BaseQueryApi,
  extraOptions?: BaseQueryExtraOptions<BaseQueryFn>,
  baseQuery?: BaseQueryFn
) => Promise<any>;

const getAllCompaniesHandler: QueryFn = (arg) => {
  if (!arg) {
    return new Promise((resolve, reject) => {
      reject();
    }).catch((err) => {
      return mapErrorFromMessage('No ID provided');
    });
  }
  return CastingCompaniesQueryApi.getAllCompanies(arg)
    .then((response) => {
      return { data: response };
    })
    .catch(mapErrorFromResponse);
};

// Define our single API slice object
const companiesApiSlice = createApi({
  reducerPath: 'companies-api',
  baseQuery: fetchBaseQuery({ baseUrl: process.env.NEXT_PUBLIC_API_BASE_PATH }),
  tagTypes: ['companies'],
  endpoints: (builder) => ({
    // GET
    getAllCompanies: builder.query<CompanyWithSettings[], number>({
      queryFn: getAllCompaniesHandler,
      providesTags: ['companies'],
    }),
  }),
});

export const { useGetAllCompaniesQuery, reducer } = companiesApiSlice; // hook + reducer export

export { companiesApiSlice }; // slice export
