import {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';

import { Input } from '../Input';
import { NumericInput } from '../NumericInput';

interface Props {
  value: number;
  onChange?: (val: number) => void;
  onBlur?: (val: number) => void;
  status?: '' | 'error' | 'warning';
}

const MeasurementNumericalInput = styled(NumericInput)`
  width: 44px;
  margin-left: 0 !important;
  input.ant-input-number-input {
    padding-left: 8px;
    padding-right: 0px;
  }
  div.ant-input-number-wrapper {
    border: 1px solid ${(props) => props.theme.palette.border[1]};
    &:has(div.ant-input-number-status-error) {
      border-color: ${(props) => props.theme.palette.color[0]};
    }
    border-radius: 4px;
    div.ant-input-number-group-addon {
      padding-left: 0;
      padding-right: 8px;
      background: white;
      color: ${(props) => props.theme.palette.greyscale[5]};
      font-size: 12px;
      line-height: 15px;
      border: 0;
    }

    &:has(div.ant-input-number-focused, div.ant-input-number:hover, div.ant-input-number-group-addon:hover) {
      border: 1px solid ${(props) => props.theme.palette.primary[0]};
      transition: border 0.25s;
      div.ant-input-number {
        border: 0;
        box-shadow: none;
      }
    }
  }
  div.ant-input-number {
    border-right: 0;
    border: 0;
    &:hover {
      border-color: ${(props) => props.theme.palette.border[1]};
    }
  }
`;

const NumericMeasurementInput = ({
  value,
  onChange,
  onBlur,
  status = '',
  ...otherProps
}: Props): JSX.Element => {
  const [_value, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const _onChange = (value: number) => {
    setInternalValue(value);
    if (onChange) onChange(value ? value : null);
  };
  const _onBlur: FocusEventHandler<HTMLInputElement> = (ele) => {
    const rawValue = ele.target.value;
    if (rawValue) {
      if (onChange) onChange(parseInt(rawValue as string));
      if (onBlur) onBlur(parseInt(rawValue as string));
    } else {
      if (onChange) onChange(null);
      if (onBlur) onBlur(null);
    }
  };

  return (
    <>
      <MeasurementNumericalInput
        {...otherProps}
        status={status}
        value={_value}
        onChange={_onChange}
        onBlur={_onBlur}
        addonAfter={
          <span
            onClick={(event) => {
              (
                (event.target as HTMLSpanElement)
                  .parentElement as HTMLSpanElement
              ).parentElement
                .querySelector('input')
                .focus();
            }}
          ></span>
        }
        controls={false}
        onPressEnter={(e) => {
          _onBlur(e as unknown as React.FocusEvent<HTMLInputElement, Element>);
          (e.target as unknown as any).blur();
        }}
      />
    </>
  );
};

interface CustomMeasurementProps {
  value?: string;
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  status?: '' | 'error' | 'warning';
}

const CustomMeasurementInput = ({
  value,
  onChange,
  onBlur,
  status = '',
  ...otherProps
}: CustomMeasurementProps): JSX.Element => {
  const [_value, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const _onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setInternalValue(value);
    if (onChange) onChange(value ? value : null);
  };
  const _onBlur: FocusEventHandler<HTMLInputElement> = (ele) => {
    const rawValue = ele.target.value;
    if (rawValue) {
      if (onChange) onChange(rawValue);
      if (onBlur) onBlur(rawValue);
    } else {
      if (onChange) onChange(null);
      if (onBlur) onBlur(null);
    }
  };

  return (
    <Input
      {...otherProps}
      value={_value}
      onChange={_onChange}
      onBlur={_onBlur}
    />
  );
};

export { CustomMeasurementInput, NumericMeasurementInput };
