import Link from 'next/link';

import { EverysetLogo } from '@assets/img';
import { LogoProps } from '@assets/img/EverysetLogo';
import SaaSLogo from '@assets/img/SaaSLogo';
import { useClientEnvironmentContext } from '@context/ClientEnvironmentContext/ClientEnvironmentContext';

export const EverysetLogoLink = (props: LogoProps): JSX.Element => {
  const { isEveryset } = useClientEnvironmentContext();

  return (
    <Link href='/' passHref legacyBehavior>
      <a style={{ display: 'contents' }}>
        {isEveryset ? (
          <EverysetLogo {...props} style={{ cursor: 'pointer' }} />
        ) : (
          <SaaSLogo style={{ cursor: 'pointer' }} />
        )}
      </a>
    </Link>
  );
};
