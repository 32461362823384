export const Mail = ({
  width = 20,
  height = 16,
  className = '',
  stroke = '#323232',
  strokeWidth = 1.5,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' mail-icon'}
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 2.8V2.8C1 3.4 1.3 3.9 1.8 4.3L7.8 8.4C9.2 9.3 10.9 9.3 12.3 8.4L18.3 4.4C18.7 3.9 19 3.4 19 2.8V2.8C19 1.8 18.2 1 17.2 1H2.8C1.8 1 1 1.8 1 2.8Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1 3V13C1 14.1 1.9 15 3 15H17C18.1 15 19 14.1 19 13V3'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.588 14.412L7.682 8.31799'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.36 8.36L18.412 14.412'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
