const Camera = ({
  width = 20,
  height = 20,
  stroke = '#494949',
  className = '',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' camera-icon'}
    {...otherProps}
  >
    <path
      d='M13.332 8.57706L16.228 6.79194C16.4851 6.63344 16.8079 6.62646 17.0716 6.77368C17.3353 6.92091 17.4987 7.1993 17.4987 7.50132V13.332C17.4987 13.634 17.3353 13.9124 17.0716 14.0596C16.8079 14.2068 16.4852 14.1999 16.2281 14.0414L13.332 12.2562'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <rect
      x='2.5'
      y='5'
      width='10.8333'
      height='10.8333'
      rx='3'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Camera;
