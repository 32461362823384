const Archive = ({
  width = 20,
  height = 20,
  stroke,
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 20 21'
    {...props}
  >
    <path
      stroke={stroke || '#323232'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M16.667 9.66V7.717c0-.92-.747-1.667-1.667-1.667h-4.559a.833.833 0 01-.689-.364l-1.17-1.721a.833.833 0 00-.69-.365H4.167c-.92 0-1.667.747-1.667 1.667v10.417c0 .69.56 1.25 1.25 1.25v0c.69 0 1.25-.56 1.25-1.25V11.1c0-.92.746-1.666 1.667-1.666h9.166c.92 0 1.667.746 1.667 1.666v4.167c0 .92-.746 1.667-1.667 1.667H3.75'
    ></path>
  </svg>
);

export { Archive };
