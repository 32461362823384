import { ApiNames, ApiProxy, ApiRequest } from '../../apiProxy';

export class CastingListsQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Lists;

  public static getCastingListColumns(
    castingListId: number
  ): Promise<CastingListColumn[]> {
    const endpoint = `/projectLists/${castingListId}/columns`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getCastingListValues(
    castingListId: number
  ): Promise<CastingListValueReturn> {
    const endpoint = `/projectLists/${castingListId}/values`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectListUsers(
    castingListId: number
  ): Promise<FullListUser[]> {
    const endpoint = `/projectLists/${castingListId}/users`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectListUsersEligibility(
    castingListId: number,
    listMemberIds: number[],
    list_status: ListStatus
  ): Promise<GetListUsersEligibilityResponse> {
    const endpoint = `/projectLists/${castingListId}/users/eligible?list_member_ids=[${listMemberIds}]&list_status=${list_status}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getProjectListUsersWithPhotos(
    castingListId: number
  ): Promise<FullListUserWithPhotos[]> {
    const endpoint = `/projectLists/${castingListId}/usersWithPhotos`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getCastingListsByProject(
    projectId: number
  ): Promise<CastingListWithCount[]> {
    const endpoint = `/projects/${projectId}/projectLists`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getListFilterSettings(
    castingListId: number
  ): Promise<ListFilterSettings> {
    const endpoint = `/projectLists/${castingListId}/filterSettings`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getListSortSettings(
    castingListId: number
  ): Promise<ListSortSettings> {
    const endpoint = `/projectLists/${castingListId}/sortSettings`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static copyToListColumnCheck(
    castingListId: number,
    sourceColumnIds: number[]
  ): Promise<ExistingColumnInTargetListDto[]> {
    const endpoint = `/projectLists/${castingListId}/copyToListColumnCheck?column_ids=${JSON.stringify(
      sourceColumnIds
    )}`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }
}
