import type { SVGProps } from 'react';

export const Clapper = ({
  width = 16,
  height = 16,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className + ' clapper-icon'}
      {...otherProps}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.332 14H2.66536C1.9287 14 1.33203 13.4033 1.33203 12.6667V3.33333C1.33203 2.59667 1.9287 2 2.66536 2H13.332C14.0687 2 14.6654 2.59667 14.6654 3.33333V12.6667C14.6654 13.4033 14.0687 14 13.332 14Z'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.33203 5.33333H14.6654'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.33203 5.33333L5.9987 2'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 5.33333L10.6667 2'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
