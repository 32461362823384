const Settings = ({
  width = 26,
  height = 26,
  onClick,
}: {
  width?: number;
  height?: number;
  onClick?: () => void;
}): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 26 26'
    fill='none'
    onClick={onClick}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.8029 3.99658C14.225 3.99658 14.6017 4.26161 14.7443 4.65886L15.2525 6.07445C15.3332 6.29705 15.4898 6.48409 15.6947 6.60267L17.2024 7.47303C17.4071 7.59115 17.647 7.63297 17.8796 7.59108L19.3603 7.32397C19.776 7.24837 20.1945 7.44217 20.4057 7.80817L21.206 9.18874C21.4178 9.55375 21.3778 10.0124 21.106 10.3352L20.1346 11.4837C19.9816 11.6643 19.8976 11.8933 19.8975 12.13V13.8707C19.8976 14.1074 19.9816 14.3364 20.1346 14.517L21.106 15.6654C21.3778 15.9882 21.4178 16.4469 21.206 16.8119L20.4057 18.1925C20.1946 18.5581 19.7767 18.7518 19.3613 18.6767L17.8806 18.4096C17.648 18.3677 17.4081 18.4095 17.2034 18.5276L15.6957 19.398C15.4908 19.5166 15.3342 19.7036 15.2535 19.9262L14.7453 21.3418C14.6025 21.7394 14.2254 22.0044 13.8029 22.0041H12.2023C11.7802 22.0041 11.4036 21.7391 11.2609 21.3418L10.7527 19.9262C10.672 19.7039 10.5159 19.517 10.3115 19.398L8.80286 18.5276C8.59814 18.4095 8.3582 18.3677 8.12558 18.4096L6.64496 18.6767C6.2292 18.7523 5.81077 18.5585 5.59953 18.1925L4.7992 16.8119C4.58739 16.4469 4.62742 15.9882 4.89924 15.6654L5.87064 14.517C6.02364 14.3364 6.10765 14.1074 6.10774 13.8707V12.13C6.10765 11.8933 6.02364 11.6643 5.87064 11.4837L4.90924 10.3352C4.63742 10.0124 4.5974 9.55375 4.8092 9.18874L5.60953 7.80817C5.82064 7.44256 6.23853 7.24882 6.65397 7.32397L8.13458 7.59108C8.3672 7.63297 8.60714 7.59115 8.81187 7.47303L10.3205 6.60267C10.5249 6.48371 10.681 6.29677 10.7617 6.07445L11.2699 4.65886C11.4114 4.2647 11.7835 4.00042 12.2023 3.99658H13.8029Z'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx='13.0011'
      cy='13.0002'
      r='2.75115'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Settings;
