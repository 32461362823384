export const MagicWand = ({
  width = '24',
  height = '24',
  stroke = '#494949',
  ...otherProps
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        id='Path'
        d='M18.6322 5.36977L19.3522 4.64977'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_2'
        d='M19.3722 14.3909L18.6122 13.6209'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_3'
        d='M10.36 5.36977L9.64 4.64977'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_4'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.20525 21.2062L2.79325 19.7932C2.40225 19.4022 2.40225 18.7692 2.79325 18.3782L12.3702 8.79716C12.7612 8.40616 13.3943 8.40616 13.7843 8.79716L15.1962 10.2102C15.5872 10.6012 15.5872 11.2342 15.1962 11.6252L5.61925 21.2062C5.22925 21.5972 4.59625 21.5972 4.20525 21.2062Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_5'
        d='M13.2011 13.6208L10.3711 10.8008'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_6'
        d='M14.4922 3.52V2.5'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        id='Path_7'
        d='M20.4805 9.50977H21.5005'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
