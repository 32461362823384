import { createContext, ReactNode, useContext } from 'react';

import { AuthUserSession } from '@lib/types/authUser.types';

import { useSession } from './useSession';

interface IAuthContext {
  token: string;
  isAuthenticated: boolean;
  authUserSession: AuthUserSession;
  loading?: boolean;
  setLoading?: (loading: boolean) => void;
  logout?: () => void;
}

const AuthContext = createContext({} as IAuthContext);

type Props = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const { authUserSession, loading, setLoading, logout } = useSession();

  return (
    <AuthContext.Provider
      value={{
        token: null,
        isAuthenticated: !!authUserSession,
        authUserSession,
        loading,
        setLoading,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// custom hook so we only have to import useContext in this component
export const useAuth: () => IAuthContext = () =>
  useContext<IAuthContext>(AuthContext);
