const CircleQuestion = ({
  width = 21,
  height = 20,
  className,
  stroke = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 21 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' circle-question-icon'}
    {...props}
  >
    <circle
      cx='10.2077'
      cy='10'
      r='7.50312'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M8.27832 8.07062C8.47584 7.21932 9.2512 6.62907 10.1244 6.66531C11.1016 6.61118 11.9393 7.35585 12.0001 8.33267C12.0001 9.58645 10.2077 10 10.2077 10.8337'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.3121 13.1263C10.3121 13.1839 10.2654 13.2305 10.2079 13.2305C10.1503 13.2305 10.1036 13.1839 10.1036 13.1263C10.1036 13.0688 10.1503 13.0221 10.2079 13.0221'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.208 13.0221C10.2656 13.0221 10.3122 13.0687 10.3122 13.1263'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { CircleQuestion };
