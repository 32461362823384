const URL = process.env.NEXT_PUBLIC_API_BASE_PATH;

const buildEndpoint = (subApi: string) => {
  if (process.env.NEXT_PUBLIC_NODE_ENV === 'local') return URL;
  return `${URL}/${subApi}`;
};

export enum ApiNames {
  Lists = 'CastingListsApi',
  Galleries = 'CastingGalleriesApi',
  Companies = 'CompaniesApi',
  CastCompanies = 'CastingCompaniesApi',
  Bookings = 'CastingBookingsApi',
  CastingProjects = 'CastingProjectsApi',
  Projects = 'ProjectsApi',
  ProjectLocations = 'ProjectLocationsApi',
  Locations = 'LocationsApi',
  Permissions = 'PermissionsApi',
  Photos = 'PhotosApi',
  Users = 'UsersApi',
  CastingSearch = 'CastingSearchApi',
  CastingApplications = 'CastingApplicationsApi',
  UserPhotos = 'UserPhotosApi',
  Timesheets = 'TimesheetsApi',
  Integrations = 'IntegrationsApi',
  Vehicles = 'Vehicles',
  Jobs = 'CastingJobsApi',
  Reports = 'ReportsApi',
  PayRates = 'PayRatesApi',
}

export const awsApiConfig: AwsApiConfig = {
  endpoints: [
    {
      name: ApiNames.Lists,
      endpoint: buildEndpoint('cast_list'),
    },
    {
      name: ApiNames.CastingSearch,
      endpoint: buildEndpoint('cast_search'),
    },
    {
      name: ApiNames.Galleries,
      endpoint: buildEndpoint('cast_galleries'),
    },
    {
      name: ApiNames.Companies,
      endpoint: buildEndpoint('com'),
    },
    {
      name: ApiNames.CastCompanies,
      endpoint: buildEndpoint('cast_com'),
    },
    {
      name: ApiNames.Bookings,
      endpoint: buildEndpoint('cast_bookings'),
    },
    {
      name: ApiNames.CastingProjects,
      endpoint: buildEndpoint('cast_proj'),
    },
    {
      name: ApiNames.Projects,
      endpoint: buildEndpoint('proj'),
    },
    {
      name: ApiNames.ProjectLocations,
      endpoint: buildEndpoint('prol'),
    },
    {
      name: ApiNames.Locations,
      endpoint: buildEndpoint('loc'),
    },
    {
      name: ApiNames.Permissions,
      endpoint: buildEndpoint('emp'),
    },
    {
      name: ApiNames.Photos,
      endpoint: buildEndpoint('phot/photos'),
    },
    {
      name: ApiNames.Users,
      endpoint: buildEndpoint('usrs/users'),
    },
    {
      name: ApiNames.CastingApplications,
      endpoint: buildEndpoint('cast_applications'),
    },
    {
      name: ApiNames.UserPhotos,
      endpoint: buildEndpoint('uphot/userPhotos'),
    },
    {
      name: ApiNames.Timesheets,
      endpoint: buildEndpoint('tims'),
    },
    {
      name: ApiNames.Integrations,
      endpoint: buildEndpoint('integrations'),
    },
    {
      name: ApiNames.Vehicles,
      endpoint: buildEndpoint('usrs'),
    },
    {
      name: ApiNames.Jobs,
      endpoint: buildEndpoint('cast_jobs'),
    },
    {
      name: ApiNames.Reports,
      endpoint: buildEndpoint('repoaq'),
    },
    {
      name: ApiNames.PayRates,
      endpoint: buildEndpoint('pay_r'),
    },
  ],
};
