import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '../../apiProxy';

export class PayRatesCommandApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.PayRates;

  public static createDefaultPayRate(
    projectId: number,
    payRateDtos: DefaultPayRateDto[]
  ): Promise<DefaultPayRate[]> {
    const endpoint = `/project/${projectId}/defaults/payRate`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.POST, payRateDtos)
    );
  }

  public static updateDefaultPayRate(
    projectId: number,
    payRateDto: DefaultPayRateDto
  ): Promise<DefaultPayRate[]> {
    const endpoint = `/project/${projectId}/defaults/payRate?label=${payRateDto.label}`;
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, endpoint, HttpMethod.PUT, payRateDto)
    );
  }
}
