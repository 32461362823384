import type { SVGProps } from 'react';

const Star = ({
  width = 18,
  height = 16,
  className = '',
  stroke = 'currentColor',
  strokeWidth = 1.5,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className + ' star-icon'}
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 12.6496L4.14917 14.9538L5.15667 9.85378L1.5 6.11378L6.64917 5.47128L9 0.787109L11.3508 5.47128L16.5 6.11378L12.8433 9.85378L13.8508 14.9538L9 12.6496Z'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export { Star };
