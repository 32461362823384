import styled from 'styled-components';

import { EverysetLogo } from '@assets/img';
import SaaSLogo from '@assets/img/SaaSLogo';
import { useClientEnvironmentContext } from '@context/ClientEnvironmentContext/ClientEnvironmentContext';

export const SplashScreen = (): JSX.Element => {
  const { isEveryset } = useClientEnvironmentContext();

  return (
    <StyledContainer>
      {isEveryset ? <EverysetLogo width='20vw' /> : <SaaSLogo />}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  height: 100vh;
  display: grid;
  place-content: center;
`;
