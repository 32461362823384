const _union_style = {
  backgroundColorIndex: 5,
  colorIndex: 0,
  borderIndex: 5,
};
const _non_union_style = {
  backgroundColorIndex: 4,
  colorIndex: 0,
  borderIndex: 4,
};
const _unregisteredStyle = {
  backgroundColorIndex: 0,
  colorIndex: 0,
  borderIndex: 0,
};

export const UNION_STATUS_FORMATTING = {
  'sag-aftra': {
    short: 'SAG',
    ..._union_style,
  },
  'sag-eligible': {
    short: 'SAG-E',
    ..._non_union_style,
  },
  'taft-hartley': {
    short: 'TH',
    ..._union_style,
  },
  'non-union': {
    short: 'N/U',
    ..._non_union_style,
  },
  ok30: {
    short: 'OK30',
    ..._union_style,
  },
  'ok-nu': {
    short: 'OK NU',
    ..._non_union_style,
  },
  ficore: {
    short: 'FiC',
    ..._union_style,
  },
  'must-join': {
    short: 'MJ',
    ..._non_union_style,
  },
  null: {
    short: 'Not Reg',
    ..._unregisteredStyle,
  },
};

export const UNION_STATUSES_FOR_SEARCH = [
  {
    internal: 'sag-aftra',
    label: 'SAG-AFTRA',
  },
  {
    internal: 'ficore',
    label: 'FiCore',
  },
  {
    internal: 'non-union',
    label: 'Non-Union',
    includes: ['sag-eligible', 'ok30', 'ok-nu', 'must-join', 'null'],
  },
];

export const UNION_STATUSES = ['sag-aftra', 'taft-hartley', 'ok30', 'ficore'];

// UNION_STATUSES_SORT_ORDER_MAP also exists on casting-galleries service
export const UNION_STATUSES_SORT_ORDER_MAP = {
  'sag-aftra': 1,
  ficore: 2,
  ok30: 3,
  'taft-hartley': 4,
  'ok-nu': 5,
  'sag-eligible': 6,
  'non-union': 7,
  'must-join': 8,
  unregistered: 9,
};

export const UNION_STATUS_OPTIONS = [
  {
    id: 'ef096de9-5ae5-49c0-a843-274779d14835',
    text: 'Not Registered',
    value: null,
    abbrev: 'NOT REG',
  },
  {
    id: '0ff95f31-79dc-40fd-9647-75e48db5d5e2',
    text: 'Non-Union',
    value: 'non-union',
    abbrev: 'N/U',
    otherFormats: ['NU', 'N/U', 'Non Union', 'non-union', 'NonUnion'],
  },
  {
    id: '9e64f580-c8fa-4f19-8036-ed2b2fd2ace5',
    text: 'SAG-Eligible',
    value: 'sag-eligible',
    abbrev: 'SAG-E',
  },
  {
    id: 'c7bc01de-cb66-471b-b404-e80e1286a331',
    text: 'SAG-AFTRA',
    value: 'sag-aftra',
    abbrev: 'SAG',
    otherFormats: ['Union', 'SAG', 'SAG AFTRA'],
  },
  {
    id: 'd3ad8b1d-b0a1-4e43-83b9-8297b08d4b93',
    text: 'Taft Hartley',
    value: 'taft-hartley',
    abbrev: 'TH',
    otherFormats: ['TH', 'th'],
  },
  {
    id: '28bbe752-e2a7-48b8-8c66-68e3f4674dbc',
    text: 'OK30',
    value: 'ok30',
    abbrev: 'OK30',
    otherFormats: ['ok30', 'ok 30', 'OK 30'],
  },
  {
    id: '16299507-3689-4fda-bd19-c75811b2621a',
    text: 'FiCore',
    value: 'ficore',
    abbrev: 'FiC',
  },
  {
    id: 'dd6ffd45-32b4-437f-8b74-e73bb546a845',
    text: 'OK NU',
    value: 'ok-nu',
    abbrev: 'OK NU',
    otherFormats: ['OKNU', 'OK-NU', 'ok nu'],
  },
  {
    id: 'ca370ab1-722d-4a75-9d87-7a701a35e252',
    text: 'Must Join',
    value: 'must-join',
    abbrev: 'MJ',
  },
];
export const UNION_STATUS_SELECTION_OPTIONS = UNION_STATUS_OPTIONS.filter(
  (option) => option.value
);

export const VOUCHER_UNION_STATUSES = [
  {
    text: 'OK30',
    value: 'ok30',
    abbrev: 'OK30',
    otherFormats: ['ok30'],
  },
  {
    text: 'Taft Hartley',
    value: 'taft-hartley',
    abbrev: 'TH',
    otherFormats: ['TH'],
  },
  {
    text: 'OK NU',
    value: 'ok-nu',
    abbrev: 'OK NU',
  },
];

export const SAG_STATUSES = ['sag-aftra', 'ficore'];
export const UNIT_MAP = {
  Main: 'Main',
  Fitting: 'Fit',
  Interview: 'Int.',
  '2nd Unit': '2nd',
  'Stills Photography': 'Stills',
  'Makeup Test': 'M/U Test',
  'Camera Test': 'Cam Test',
};
