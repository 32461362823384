const Schedule = (): JSX.Element => (
  <svg
    width='19'
    height='19'
    viewBox='0 0 19 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M12.2695 1.56348V4.56348'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.26953 1.56348V4.56348'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.51953 6.81348H16.0195'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.5195 3.06348H4.01953C3.19078 3.06348 2.51953 3.73473 2.51953 4.56348V14.3135C2.51953 15.1422 3.19078 15.8135 4.01953 15.8135H14.5195C15.3483 15.8135 16.0195 15.1422 16.0195 14.3135V4.56348C16.0195 3.73473 15.3483 3.06348 14.5195 3.06348Z'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.51953 9.81348H13.0195'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.51953 12.8135H13.0195'
      stroke='#BABABA'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Schedule;
