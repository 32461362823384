const CloseIcon = ({
  width = 16,
  color = '#E14A4A',
  ...props
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{ cursor: 'pointer' }}
    {...props}
  >
    <path
      d='M4 4L12 12'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 4L4 12'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { CloseIcon };
