export const VEHICLE_TYPE_OPTIONS = [
  'Convertible',
  'SUV',
  'Sedan',
  'Crossover',
  'Station Wagon',
  'Van',
  'Sports',
  'Truck',
  'Motorcycle',
  'Other',
];
export const VEHICLE_COLOR_OPTIONS = [
  'White',
  'Black',
  'Grey',
  'Silver',
  'Gold',
  'Tan',
  'Brown',
  'Burgundy',
  'Red',
  'Orange',
  'Yellow',
  'Green',
  'Blue',
  'Purple',
];
export const VEHICLE_MAKE_OPTIONS = [
  'Acura',
  'Aston Martin',
  'Audi',
  'BMX',
  'Bentley',
  'Buick',
  'Cadillac',
  'Chevrolet',
  'Chrysler',
  'Dodge',
  'Ferrari',
  'Fiat',
  'Ford',
  'GMC',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Jaguar',
  'Jeep',
  'Kia',
  'Lamborghini',
  'Lexus',
  'Lincoln',
  'MINI',
  'Maserati',
  'Mazda',
  'Mercedes-Benz',
  'Mitsubishi',
  'Nissan',
  'Oldsmobile',
  'Plymouth',
  'Pontiac',
  'Porsche',
  'Ram',
  'Rolls-Royce',
  'Saturn',
  'Scion',
  'Subaru',
  'Tesla',
  'Toyota',
  'Volkswagon',
  'Volvo',
];
export const VEHICLE_CONDITION_OPTIONS = [
  'Excellent / New',
  'Good / Used',
  'Poor / Visible Damages',
];
