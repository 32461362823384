const ScrollArrowUp = ({
  width = 22,
  height = 30,
  color = '#323232',
  onClick,
  style,
}: {
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 22 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={{
      ...style,
    }}
    onClick={onClick}
  >
    <path
      d='M10.9999 5.13208V28.1321'
      stroke={color}
      strokeWidth='2.50391'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.09497 14.0304L11 5.12537L19.905 14.0304'
      stroke={color}
      strokeWidth='2.50391'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.09717 1.62188H19.9028'
      stroke={color}
      strokeWidth='2.50391'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { ScrollArrowUp };
