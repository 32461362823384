import { ApiNames, ApiProxy, ApiRequest, HttpMethod } from '@lib/api/apiProxy';

export class UsersQueryApi {
  private static apiProxy = ApiProxy.getInstance();
  private static apiName = ApiNames.Users;

  public static getSagAftraInfo(userIds: number[]): Promise<SafAftraInfo[]> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, '/get_sag_aftra', HttpMethod.POST, {
        user_ids: userIds,
      })
    );
  }

  public static getUser(userId: number): Promise<UserDetail> {
    return this.apiProxy.execute(new ApiRequest(this.apiName, `/${userId}`));
  }

  public static getUserProfile(userId: number): Promise<UserProfile> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/profile`)
    );
  }

  public static getUserAppearance(userId: number): Promise<UserAppearance> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/appearance`)
    );
  }

  public static getUserVehicles(userId: number): Promise<UserVehicle[]> {
    const endpoint = `/${userId}/vehicles`;
    return this.apiProxy.execute(new ApiRequest(this.apiName, endpoint));
  }

  public static getMatchingProfiles(
    email: string,
    phone: string
  ): Promise<MatchingProfileResult[]> {
    const query: {
      email?: string;
      phone?: string;
    } = {};
    if (email) {
      query.email = email;
    }
    if (phone) {
      query.phone = phone;
    }
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        `/matchingProfiles?query=${encodeURIComponent(JSON.stringify(query))}`
      )
    );
  }

  public static getMultipleMatchingProfiles(
    matchingProfileRequests: MatchingProfileRequest[]
  ): Promise<MultipleMatchinProfileResult> {
    return this.apiProxy.execute(
      new ApiRequest(
        this.apiName,
        '/multipleMatchingProfiles',
        HttpMethod.POST,
        matchingProfileRequests
      )
    );
  }

  public static getUserHistory(userId: number): Promise<HistoryProjectGroup[]> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/history`)
    );
  }

  public static getUserReviews(userId: number): Promise<UserReview[]> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/reviews`)
    );
  }

  public static getUserNotificationPreferences(
    userId: number
  ): Promise<UserNotificationPreference[]> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/notification_preferences`)
    );
  }

  public static getUserLatestI9(userId: number): Promise<LatestI9> {
    return this.apiProxy.execute(
      new ApiRequest(this.apiName, `/${userId}/latestI9`)
    );
  }
}
