const Arrow = ({
  direction,
  width = 6,
  height = 12,
  color = 'currentColor',
  onClick,
  style,
}: {
  direction: 'up' | 'right' | 'down' | 'left';
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
}): JSX.Element => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width}
    height={height}
    viewBox='0 0 8 15'
    fill='none'
    onClick={onClick}
    style={{
      ...style,
      transform:
        direction === 'up'
          ? 'rotate(90deg)'
          : direction === 'right'
          ? 'rotate(180deg)'
          : direction === 'down'
          ? 'rotate(270deg)'
          : 'rotate(0deg)',
    }}
  >
    <path
      d='M6.91675 1.70129L1.08342 7.53463L6.91675 13.368'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export { Arrow };
