import type { InputNumberProps } from 'antd';
import { FocusEventHandler, useEffect, useState } from 'react';
import styled from 'styled-components';

import { NumericInput } from '../NumericInput';

type Props = Omit<InputNumberProps, 'onChange' | 'onBlur' | 'value'> & {
  value: number;
  onChange?: (val: number) => void;
  onBlur?: (val: number) => void;
  status?: '' | 'error' | 'warning';
};

const WeightNumericalInput = styled(NumericInput)`
  width: 85px;
  margin-left: 0 !important;
  input.ant-input-number-input {
    padding: 7px 0 7px 8px;
    font-size: 12px;
  }
  div.ant-input-number-wrapper {
    border: 1px solid ${(props) => props.theme.palette.border[1]};
    &:has(div.ant-input-number-status-error) {
      border-color: ${(props) => props.theme.palette.color[0]};
    }
    border-radius: 4px;
    div.ant-input-number-group-addon {
      padding-left: 0;
      padding-right: 10px;
      background: white;
      color: ${(props) => props.theme.palette.greyscale[5]};
      font-size: 12px;
      line-height: 15px;
      border: 0;
    }

    &:has(div.ant-input-number-focused, div.ant-input-number:hover, div.ant-input-number-group-addon:hover) {
      border: 1px solid ${(props) => props.theme.palette.primary[0]};
      transition: border 0.25s;
      div.ant-input-number {
        border: 0;
        box-shadow: none;
      }
    }
  }
  div.ant-input-number {
    border-right: 0;
    border: 0;
    &:hover {
      border-color: ${(props) => props.theme.palette.border[1]};
    }
  }
`;

const WeightInput = ({
  value,
  onChange,
  onBlur,
  min = 0,
  max = 400,
  status = '',
  ...otherProps
}: Props): JSX.Element => {
  const [_value, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const _onChange = (value: number) => {
    setInternalValue(value);
    if (onChange) onChange(value ? value : null);
  };
  const _onBlur: FocusEventHandler<HTMLInputElement> = (ele) => {
    const rawValue = ele.target.value;
    if (rawValue) {
      if (onChange) onChange(parseInt(rawValue as string));
      if (onBlur) onBlur(parseInt(rawValue as string));
    } else {
      if (onChange) onChange(null);
      if (onBlur) onBlur(null);
    }
  };

  return (
    <>
      <WeightNumericalInput
        {...otherProps}
        status={status}
        value={_value}
        min={min}
        max={max}
        onChange={_onChange}
        onBlur={_onBlur}
        addonAfter={
          <span
            onClick={(event) => {
              (
                (event.target as HTMLSpanElement)
                  .parentElement as HTMLSpanElement
              ).parentElement
                .querySelector('input')
                .focus();
            }}
          >
            lbs
          </span>
        }
        controls={false}
        onPressEnter={(e) => {
          _onBlur(e as unknown as React.FocusEvent<HTMLInputElement, Element>);
          (e.target as unknown as any).blur();
        }}
      />
    </>
  );
};

export { WeightInput };
