const Calendar = (): JSX.Element => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M13.3346 1.6665V4.99984'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.66667 1.6665V4.99984'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 7.49967H17.5'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.8333 3.33301H4.16667C3.24583 3.33301 2.5 4.07884 2.5 4.99967V15.833C2.5 16.7538 3.24583 17.4997 4.16667 17.4997H15.8333C16.7542 17.4997 17.5 16.7538 17.5 15.833V4.99967C17.5 4.07884 16.7542 3.33301 15.8333 3.33301Z'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.83203 10.8332H14.1654'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.83203 14.1667H14.1654'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Calendar;
