import type { SVGProps } from 'react';

export const Chevron = ({
  direction = 'down',
  width = 14,
  height = 8,
  stroke = '#494949',
  className = '',
  style,
  ...otherProps
}: SVGProps<SVGSVGElement> & {
  direction?: 'up' | 'right' | 'down' | 'left';
}): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transform:
          direction === 'up'
            ? 'rotate(180deg)'
            : direction === 'right'
            ? 'rotate(270deg)'
            : direction === 'down'
            ? 'rotate(0deg)'
            : 'rotate(90deg)',
        ...style,
      }}
      className={className + ' chevron-icon'}
      {...otherProps}
    >
      <path
        d='M1.37451 1.08331L7.20785 6.91665L13.0412 1.08331'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
