const Ruler = ({
  width = 16,
  height = 16,
  stroke = 'currentColor',
  strokeWidth = '1.5',
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...otherProps}
    >
      <path
        d='M2.82031 9.57617L4.17088 10.9274'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.875 5.52344L8.22623 6.87467'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.17188 8.22559L5.07292 9.12596'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.52344 6.875L6.42448 7.77537'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.22656 4.17188L9.12694 5.07292'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.57422 2.82129L10.4753 3.72166'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.81932 12.2783C2.07342 11.5324 2.07342 10.3231 2.81932 9.57716L9.57566 2.82082C10.3216 2.07492 11.5309 2.07492 12.2768 2.82082L13.1787 3.72271C13.9246 4.46861 13.9246 5.67795 13.1787 6.42385L6.42235 13.1802C5.67645 13.9261 4.46711 13.9261 3.72121 13.1802L2.81932 12.2783Z'
        stroke={stroke}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export { Ruler };
