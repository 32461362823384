const EditPenCircle = (): JSX.Element => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M5.83203 8.95786H8.33307'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M5.83203 11.8753H14.1688'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.4995 2.93295C9.19413 1.75833 5.51569 3.02497 3.63425 5.98562C1.75281 8.94627 2.16816 12.8144 4.63529 15.3082C7.10241 17.8019 10.9659 18.2587 13.9466 16.4091C16.9272 14.5595 18.2332 10.8948 17.0941 7.57702'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.6701 3.33057L11.668 8.33265'
      stroke='#494949'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EditPenCircle;
