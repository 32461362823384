import { HttpMethod } from './httpMethod';

export class ApiRequest {
  apiName: string;
  url: string;
  method: HttpMethod;
  body: HttpBody;
  httpHeaders: HttpHeaders;
  miscOptions: HttpMiscOptions;
  isPublic: boolean;

  constructor(
    apiName: string,
    url: string,
    method = HttpMethod.GET,
    body = {},
    httpHeaders = {},
    miscOptions = {},
    isPublic = false
  ) {
    this.apiName = apiName;
    this.url = url;
    this.method = method;
    this.body = body;
    this.httpHeaders = httpHeaders;
    this.miscOptions = miscOptions;
    this.isPublic = isPublic;
  }
}
