import theme from '@styles/theme';

const Location = ({
  width = 20,
  stroke = theme.palette.greyscale[6],
  strokeWidth = 1.5,
  className = '',
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={'location-icon ' + className}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10 10.8335V10.8335C8.61917 10.8335 7.5 9.71433 7.5 8.3335V8.3335C7.5 6.95266 8.61917 5.8335 10 5.8335V5.8335C11.3808 5.8335 12.5 6.95266 12.5 8.3335V8.3335C12.5 9.71433 11.3808 10.8335 10 10.8335Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0003 17.5C10.0003 17.5 4.16699 12.7083 4.16699 8.33333C4.16699 5.11167 6.77866 2.5 10.0003 2.5C13.222 2.5 15.8337 5.11167 15.8337 8.33333C15.8337 12.7083 10.0003 17.5 10.0003 17.5Z'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Location;
