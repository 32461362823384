export const Close = ({
  width = 16,
  height = 17,
  stroke = '#868686',
  className = '',
  strokeWidth = 1.5,
  ...otherProps
}: React.SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={className + ' close-icon'}
    {...otherProps}
  >
    <path
      d='M4 4.91669L12 12.9167'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12 4.91669L4 12.9167'
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
