import {
  ChangeEventHandler,
  FocusEventHandler,
  useEffect,
  useState,
} from 'react';
import type { InputProps } from 'antd';

import { Input } from '..';

const finalHeightFormatRegExp = /^(\d{1,1}('{1,1}\d{0,2}))$|^(\d{1,1})$/i;
/*
  supports following formats:
    5
    5'
    5'9
    5'99 (converts 99 inches to feet)
*/
// const heightInputRegExp = /^\d{1,1}$|^\d{1,1}$|^\d{1,1}'$|^\d{1,1}'\d{0,2}$/i;
const heightInputRegExp = /\d|'/i;

type Props = Omit<InputProps, 'onChange' | 'onBlur' | 'value'> & {
  value: string;
  onChange?: (val: string) => void;
  onBlur?: (val: string) => void;
  status?: '' | 'error' | 'warning';
  placeholder: string;
};

const HeightInput = ({
  value,
  onChange,
  onBlur,
  status = '',
  ...otherProps
}: Props): JSX.Element => {
  const [_value, setInternalValue] = useState(value);
  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const _onChange: ChangeEventHandler<HTMLInputElement> = (ele) => {
    let rawValue = ele?.target?.value;
    if (rawValue) {
      if (rawValue.length == 2 && !rawValue.includes("'"))
        rawValue = rawValue.slice(0, 1) + "'" + rawValue.slice(1);
      if (!heightInputRegExp.test(rawValue[rawValue.length - 1]))
        rawValue = rawValue.slice(0, rawValue.length - 1);
      if (rawValue.length > 3) {
        rawValue = rawValue.slice(0, 4);
      }
    }
    setInternalValue(rawValue);
    if (onChange) onChange(rawValue);
  };
  const _onBlur: FocusEventHandler<HTMLInputElement> = (ele) => {
    let rawValue = ele.target.value;
    if (rawValue) {
      if (finalHeightFormatRegExp.test(rawValue)) {
        // it's already valid!
        rawValue = rawValue
          .split("'")
          .reduce((prev, curr, index) => {
            if (index == 0) prev.push(curr);
            if (index == 1) {
              prev.push("'");
              const inches = parseInt(curr);
              if (inches >= 12) {
                prev[0] = (parseInt(prev[0]) + 1).toString();
                const remainder = (inches % 12).toString();
                prev.push(remainder);
              } else {
                prev.push(curr);
              }
            }
            return prev;
          }, [])
          .join('');
      } else {
        // it's not valid
        rawValue = '';
      }
      if (onChange) onChange(rawValue);
      if (onBlur) onBlur(rawValue);
    } else {
      if (onChange) onChange(rawValue);
      if (onBlur) onBlur(rawValue);
    }
  };
  return (
    <>
      <Input
        {...otherProps}
        status={status}
        value={_value}
        onChange={_onChange}
        onBlur={_onBlur}
        onPressEnter={(e) => {
          _onBlur(e as unknown as React.FocusEvent<HTMLInputElement, Element>);
          (e.target as unknown as any).blur();
        }}
      />
    </>
  );
};

export { HeightInput };
